import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputBase,
  TextField,
  Typography,
  Chip,
  Badge,
  FormGroup,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTheme } from "@mui/material/styles";
import { getHeaderRowStyles } from "../../../utils/themeStyles";

interface FilterPopupProps {
  conclusions: string[];
  statuses: string[];
  severities: string[];
  applyFilters: (filters: any) => void;
  currentFilters: any; // The currently applied filters
}

const FilterPopup: React.FC<FilterPopupProps> = ({
  conclusions,
  statuses,
  severities,
  applyFilters,
  currentFilters,
}) => {
  const theme = useTheme();

  const [selectedConclusions, setSelectedConclusions] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [selectedSeverities, setSelectedSeverities] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [alertStartDate, setAlertStartDate] = useState<Date | null>(null);
  const [alertEndDate, setAlertEndDate] = useState<Date | null>(null);
  const [identities, setIdentities] = useState<string[]>([]);
  const [identityInput, setIdentityInput] = useState("");

  // When currentFilters changes, update the state of this component
  useEffect(() => {
    if (currentFilters) {
      setSelectedConclusions(currentFilters.conclusions || []);
      setSelectedStatuses(currentFilters.statuses || []);
      setSelectedSeverities(currentFilters.severities || []);
      setStartDate(
        currentFilters.createdAt ? currentFilters.createdAt[0] : null
      );
      setEndDate(currentFilters.createdAt ? currentFilters.createdAt[1] : null);
      setAlertStartDate(
        currentFilters.alertTimestamp ? currentFilters.alertTimestamp[0] : null
      );
      setAlertEndDate(
        currentFilters.alertTimestamp ? currentFilters.alertTimestamp[1] : null
      );
      setIdentities(currentFilters.identities || []);
    }
  }, [currentFilters]);

  // Apply selected filters
  const handleApplyFilters = () => {
    applyFilters({
      conclusions: selectedConclusions,
      statuses: selectedStatuses,
      severities: selectedSeverities,
      createdAt: [startDate, endDate],
      alertTimestamp: [alertStartDate, alertEndDate],
      identities,
    });
  };

  // Handle checkbox change
  const handleCheckboxChange =
    (setState: React.Dispatch<React.SetStateAction<string[]>>, value: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setState((prev) =>
        event.target.checked
          ? [...prev, value]
          : prev.filter((v) => v !== value)
      );
    };

  // Calculate the number of filters applied
  const totalFiltersApplied =
    selectedConclusions.length +
    selectedStatuses.length +
    selectedSeverities.length +
    (startDate || endDate ? 1 : 0) +
    (alertStartDate || alertEndDate ? 1 : 0) +
    identities.length;

  // Get header row styles based on theme
  const headerRowStyles = getHeaderRowStyles(theme);

  return (
    <Box
      sx={{
        width: 300,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        boxShadow: 3,
        backgroundColor: theme.palette.background.paper,
        zIndex: 1000,
      }}
    >
      {/* Updated the background color based on theme */}
      <Box
        sx={{
          backgroundColor: headerRowStyles.backgroundColor,
          color: headerRowStyles.color,
          padding: headerRowStyles.padding,
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottom: headerRowStyles.borderBottom,
        }}
      >
        <Typography variant="h6">Filter Cases</Typography>
      </Box>

      {/* Conclusion Filters */}
      <Box sx={{ padding: theme.spacing(2, 2, 0, 2) }}>
        {/* Adjusted badge positioning */}
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Badge
            badgeContent={selectedConclusions.length}
            color="primary"
            sx={{ mr: 1 }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              Conclusion
            </Typography>
          </Badge>
        </Box>
        <FormGroup>
          {conclusions.map((conclusion) => (
            <FormControlLabel
              key={conclusion}
              control={
                <Checkbox
                  checked={selectedConclusions.includes(conclusion)}
                  onChange={handleCheckboxChange(
                    setSelectedConclusions,
                    conclusion
                  )}
                  sx={{
                    color: theme.palette.info.main,
                    "&.Mui-checked": {
                      color: theme.palette.info.main,
                    },
                  }}
                />
              }
              label={conclusion}
              // Removed slotProps; instead, use sx for label color
              sx={{
                "& .MuiTypography-root": {
                  color: theme.palette.mode === "dark" ? "white" : "grey.700",
                },
              }}
            />
          ))}
        </FormGroup>
      </Box>

      {/* Severity Filters */}
      <Box sx={{ padding: theme.spacing(2, 2, 0, 2) }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Badge
            badgeContent={selectedSeverities.length}
            color="primary"
            sx={{ mr: 1 }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              Severity
            </Typography>
          </Badge>
        </Box>
        <FormGroup>
          {severities.map((severity) => (
            <FormControlLabel
              key={severity}
              control={
                <Checkbox
                  checked={selectedSeverities.includes(severity)}
                  onChange={handleCheckboxChange(
                    setSelectedSeverities,
                    severity
                  )}
                  sx={{
                    color: theme.palette.info.main,
                    "&.Mui-checked": {
                      color: theme.palette.info.main,
                    },
                  }}
                />
              }
              label={severity}
              sx={{
                "& .MuiTypography-root": {
                  color: theme.palette.mode === "dark" ? "white" : "grey.700",
                },
              }}
            />
          ))}
        </FormGroup>
      </Box>

      {/* Created At Date Range */}
      <Box sx={{ padding: theme.spacing(2, 2, 0, 2) }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Badge
            badgeContent={startDate || endDate ? 1 : 0}
            color="primary"
            sx={{ mr: 1 }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              Created At
            </Typography>
          </Badge>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(date) => setStartDate(date)}
            slots={{
              textField: (props) => (
                <TextField {...props} size="small" sx={{ flex: 1, mr: 1 }} />
              ),
            }}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(date) => setEndDate(date)}
            slots={{
              textField: (props) => (
                <TextField {...props} size="small" sx={{ flex: 1 }} />
              ),
            }}
          />
        </Box>
      </Box>

      {/* Alert Time Date Range */}
      <Box sx={{ padding: theme.spacing(2, 2, 0, 2) }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Badge
            badgeContent={alertStartDate || alertEndDate ? 1 : 0}
            color="primary"
            sx={{ mr: 1 }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              Alert Time
            </Typography>
          </Badge>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <DatePicker
            label="Start Date"
            value={alertStartDate}
            onChange={(date) => setAlertStartDate(date)}
            slots={{
              textField: (props) => (
                <TextField {...props} size="small" sx={{ flex: 1, mr: 1 }} />
              ),
            }}
          />
          <DatePicker
            label="End Date"
            value={alertEndDate}
            onChange={(date) => setAlertEndDate(date)}
            slots={{
              textField: (props) => (
                <TextField {...props} size="small" sx={{ flex: 1 }} />
              ),
            }}
          />
        </Box>
      </Box>

      {/* Identity Search and Chips */}
      <Box sx={{ padding: theme.spacing(2, 2, 0, 2) }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Badge
            badgeContent={identities.length}
            color="primary"
            sx={{ mr: 1 }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              Identity
            </Typography>
          </Badge>
        </Box>

        {/* Move the Chips above the search input */}
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", mt: 1 }}>
          {identities.map((identity) => (
            <Chip
              key={identity}
              label={identity}
              onDelete={() =>
                setIdentities((prev) =>
                  prev.filter((item) => item !== identity)
                )
              }
            />
          ))}
        </Box>

        {/* Search Input and Add Button */}
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <InputBase
            value={identityInput}
            onChange={(e) => setIdentityInput(e.target.value)}
            placeholder="Search Identity"
            sx={{
              flex: 1,
              mr: 1,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: theme.shape.borderRadius,
              padding: theme.spacing(0.5, 1),
              color: theme.palette.text.primary,
              backgroundColor:
                theme.palette.mode === "dark"
                  ? theme.palette.grey[800]
                  : theme.palette.grey[100],
            }}
          />
          <Button
            variant="outlined"
            onClick={() => {
              if (identityInput && !identities.includes(identityInput))
                setIdentities([...identities, identityInput]);
              setIdentityInput("");
            }}
            sx={{
              backgroundColor: theme.palette.info.main,
              color: theme.palette.common.white,
              "&:hover": {
                backgroundColor: theme.palette.info.dark,
              },
            }}
          >
            Add
          </Button>
        </Box>
      </Box>

      {/* Action Buttons */}
      <Box sx={{ padding: theme.spacing(2) }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Button
            variant="contained"
            onClick={handleApplyFilters}
            sx={{
              backgroundColor: theme.palette.info.main,
              color: theme.palette.common.white,
              "&:hover": {
                backgroundColor: theme.palette.info.dark,
              },
            }}
          >
            {`Apply Filters (${totalFiltersApplied})`}
          </Button>
          <Button
            onClick={() => {
              setSelectedConclusions([]);
              setSelectedStatuses([]);
              setSelectedSeverities([]);
              setStartDate(null);
              setEndDate(null);
              setAlertStartDate(null);
              setAlertEndDate(null);
              setIdentities([]);
            }}
            sx={{
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.grey[300]
                  : theme.palette.grey[700],
              "&:hover": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? theme.palette.grey[800]
                    : theme.palette.grey[200],
              },
            }}
          >
            Clear All
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FilterPopup;
