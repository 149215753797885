import React from "react";
import { Typography, List, ListItem, Paper } from "@mui/material";
import { CircleTwoTone } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

interface KeyEvidenceSectionProps {
  keyIndicators: string[];
}

const KeyEvidenceSection: React.FC<KeyEvidenceSectionProps> = ({
  keyIndicators,
}) => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        flex: 1,
        padding: theme.spacing(2),
        borderRadius: "8px",
        PaperShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          marginBottom: theme.spacing(1),
        }}
      >
        Key Evidence
      </Typography>
      <List>
        {keyIndicators.map((indicator, index) => (
          <ListItem
            key={index}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              paddingLeft: 0,
              paddingY: "0.25rem",
            }}
          >
            <CircleTwoTone
              fontSize="small"
              sx={{
                color: theme.palette.grey[600],
                fontSize: "0.8rem",
                marginRight: theme.spacing(1),
                marginTop: theme.spacing(0.5),
              }}
            />
            <Typography
              variant="body2"
              sx={{ color: theme.palette.text.primary }}
            >
              {indicator}
            </Typography>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default KeyEvidenceSection;
