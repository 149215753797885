import React, { useEffect, useState } from "react";
import {
  Box,
  useTheme,
  Breadcrumbs,
  Link as MuiLink,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
  Button,
} from "@mui/material";
import {
  Link as RouterLink,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import CaseSummarySection from "../features/case-details/components/CaseSummarySection";
import EmailFlowAndInvestigativeSteps from "../features/case-details/components/EmailFlowAndInvestigativeSteps";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/rootReducer";
import { useSelector } from "react-redux";
import { fetchCaseDetail, submitFeedback } from "../api/caseDetailsApi";
import CaseDetailsLoadingSkeleton from "../features/case-details/components/CaseDetailsIsLoadingSkeleton"; // Import the skeleton component
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { setCurrentIndex } from "../features/case-navigation/redux/caseNavigationSlice";

const CaseDetailsPage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>(); // Get the id from URL params
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();

  // Determine the source of navigation
  const from = location.state?.from || "/cases";
  // Get navigation state from Redux
  const { filteredCases, currentIndex } = useAppSelector(
    (state: RootState) => state.caseNavigation
  );

  // Calculate navigation availability
  const hasNavigation = filteredCases.length > 1;
  const canGoPrevious = hasNavigation && currentIndex > 0;
  const canGoNext = hasNavigation && currentIndex < filteredCases.length - 1;

  const handlePrevious = () => {
    if (canGoPrevious) {
      const newIndex = currentIndex - 1;
      const newCaseId = filteredCases[newIndex].caseId;
      dispatch(setCurrentIndex(newIndex));
      navigate(`/cases/${newCaseId}`, { state: { from } });
    }
  };

  const handleNext = () => {
    if (canGoNext) {
      const newIndex = currentIndex + 1;
      const newCaseId = filteredCases[newIndex].caseId;
      dispatch(setCurrentIndex(newIndex));
      navigate(`/cases/${newCaseId}`, { state: { from } });
    }
  };

  // Local state to manage editing mode
  const [isEditingFeedback, setIsEditingFeedback] = useState(false);

  // Redux store data
  const caseDetail = useSelector(
    (state: RootState) => state.caseDetails.caseDetail
  );
  const loading = useSelector((state: RootState) => state.caseDetails.loading);
  const error = useSelector((state: RootState) => state.caseDetails.error);
  // Feedback submission state
  const feedbackLoading = useSelector(
    (state: RootState) => state.caseDetails.feedbackLoading
  );
  const feedbackError = useSelector(
    (state: RootState) => state.caseDetails.feedbackError
  );

  // Extract feedback from the caseDetail
  const feedbackEntry =
    caseDetail && caseDetail.feedbacks && caseDetail.feedbacks.length > 0
      ? caseDetail.feedbacks[0]
      : null;

  const feedback = isEditingFeedback ? null : feedbackEntry?.result || null;
  const feedbackReason = isEditingFeedback ? null : feedbackEntry?.text || null;

  // Local state for edit form
  const [feedbackOption, setFeedbackOption] = useState(feedback || "agree");
  const [feedbackText, setFeedbackText] = useState(feedbackReason || "");

  // Handlers for the edit form
  const handleFeedbackOptionChange = (
    event: React.MouseEvent<HTMLElement>,
    newOption: string
  ) => {
    if (newOption !== null) {
      setFeedbackOption(newOption);
    }
  };

  const handleFeedbackTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFeedbackText(event.target.value);
  };

  // Existing feedback submission handler
  const handleSubmitFeedback = async () => {
    if (!id) return;
    try {
      const token = await getAccessTokenSilently();
      await dispatch(
        submitFeedback({
          accessToken: token,
          caseId: id,
          position: feedbackOption as "agree" | "disagree",
          reason: feedbackText,
        })
      );
      setIsEditingFeedback(false); // Exit editing state
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  // New feedback submission handler
  const handleNewSubmitFeedback = async (
    position: "agree" | "disagree",
    reason: string
  ) => {
    if (!id) return;

    try {
      const token = await getAccessTokenSilently();
      if (reason) {
        setFeedbackText(reason);
      }

      await dispatch(
        submitFeedback({
          accessToken: token,
          caseId: id,
          position,
          reason: position === "disagree" ? feedbackText : "",
        })
      );

      setIsEditingFeedback(false); // Exit editing mode
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleEditFeedback = () => {
    setIsEditingFeedback(true);
    // Initialize local state with existing feedback
    setFeedbackOption(feedback || "agree");
    setFeedbackText(feedbackReason || "");
  };

  const handleCancelEdit = () => {
    setIsEditingFeedback(false);
    // Reset local state
    setFeedbackOption(feedback || "agree");
    setFeedbackText(feedbackReason || "");
  };

  const emailImageUrl = id
    ? `${process.env.REACT_APP_EMAIL_IMAGE_URL}/${id}`
    : "";

  // Fetch case details on mount
  useEffect(() => {
    const fetchData = async () => {
      if (!id) return; // Exit if id is undefined

      try {
        const token = await getAccessTokenSilently();
        dispatch(fetchCaseDetail({ accessToken: token, caseId: id }));
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };
    fetchData();
  }, [dispatch, getAccessTokenSilently, id]);

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  // Handle refresh with Snackbar feedback
  const handleRefresh = async () => {
    try {
      const token = await getAccessTokenSilently();
      await dispatch(
        fetchCaseDetail({ accessToken: token, caseId: id! })
      ).unwrap(); // unwrap to catch errors
      setSnackbarMessage("Case details refreshed successfully!");
      setSnackbarSeverity("success");
    } catch (error) {
      console.error("Error refreshing case details:", error);
      setSnackbarMessage("Failed to refresh case details.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  // Handle Snackbar close
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // Handle loading and error states
  if (loading) return <CaseDetailsLoadingSkeleton />;
  if (error)
    return (
      <Box sx={{ textAlign: "center", marginTop: theme.spacing(4) }}>
        <Typography variant="h6" color="error">
          Error: {error}
        </Typography>
        <IconButton
          onClick={handleRefresh}
          disabled={loading}
          sx={{
            color: theme.palette.info.main,
            backgroundColor: theme.palette.grey[100],
            boxShadow: theme.shadows[2],
            "&:hover": {
              backgroundColor: theme.palette.grey[200],
              boxShadow: theme.shadows[4],
            },
            borderRadius: "4px",
            marginTop: theme.spacing(2),
          }}
        >
          {loading ? <CircularProgress size={24} /> : <RefreshIcon />}
        </IconButton>
      </Box>
    );

  return (
    <Box sx={{ padding: "2rem" }}>
      {caseDetail && (
        <>
          {/* Breadcrumbs Navigation */}
          <Box sx={{ marginBottom: theme.spacing(1.5) }}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {from === "/cases" ? (
                <MuiLink
                  component={RouterLink}
                  to="/cases"
                  underline="hover"
                  color="inherit"
                  sx={{ fontSize: "0.75rem", color: theme.palette.grey[600] }}
                >
                  Cases
                </MuiLink>
              ) : (
                <MuiLink
                  component={RouterLink}
                  to="/dashboard"
                  underline="hover"
                  color="inherit"
                  sx={{ fontSize: "0.75rem", color: theme.palette.grey[600] }}
                >
                  Dashboard
                </MuiLink>
              )}
              <Typography
                sx={{ fontSize: "0.75rem", color: theme.palette.info.main }}
              >
                Subject: {caseDetail.title}
              </Typography>
            </Breadcrumbs>
          </Box>

          {/* Case Summary Section */}
          <CaseSummarySection
            caseTitle={caseDetail.title}
            caseConclusion={caseDetail.conclusion}
            keyIndicators={caseDetail.keyIndicators}
            caseNumber={caseDetail.id}
            createdOn={caseDetail.createdAt}
            attackSurface={caseDetail.attackSurface}
            alertTime={
              caseDetail.alerts && caseDetail.alerts.length > 0
                ? caseDetail.alerts[0].timestamp
                : ""
            }
            analyst={caseDetail.analyst || ""}
            analystAvatar={caseDetail.analystAvatar || ""}
            status={caseDetail.status || "Unreviewed"}
            severity={caseDetail.severity}
            device={caseDetail.attackSurface}
            from={
              caseDetail.alerts &&
              caseDetail.alerts.length > 0 &&
              caseDetail.alerts[0].data
                ? caseDetail.alerts[0].data.from_address
                : ""
            }
            description={caseDetail.summary}
            feedback={feedback}
            feedbackReason={feedbackReason}
            feedbackLoading={feedbackLoading}
            feedbackError={feedbackError}
            isEditingFeedback={isEditingFeedback}
            feedbackOption={feedbackOption}
            feedbackText={feedbackText}
            onFeedbackOptionChange={handleFeedbackOptionChange}
            onFeedbackTextChange={handleFeedbackTextChange}
            onSubmitFeedback={handleSubmitFeedback}
            onAgree={(position, reason) =>
              handleNewSubmitFeedback(position, reason)
            }
            onDisagree={(position, reason) =>
              handleNewSubmitFeedback(position, reason)
            }
            onEditFeedback={handleEditFeedback}
            onCancelEdit={handleCancelEdit}
            setFeedbackText={setFeedbackText}
            setFeedbackOption={setFeedbackOption}
          />

          {/* Email Flow and Investigative Steps */}
          <EmailFlowAndInvestigativeSteps
            emailImageUrl={emailImageUrl}
            caseDetail={caseDetail}
            analyses={
              caseDetail.details?.analyses
                ? caseDetail.details.analyses.map((analysis) => ({
                    ...analysis,
                    conclusion: analysis.conclusion ?? "",
                  }))
                : []
            }
            hasNavigation
            canGoPrevious
            canGoNext
            onPrevious={handlePrevious}
            onNext={handleNext}
          />
        </>
      )}

      {/* Snackbar for Feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CaseDetailsPage;
