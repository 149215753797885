import React from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  Avatar,
  useTheme,
} from "@mui/material";
import SeverityIndicator from "../../cases/components/SeverityIndicatorDisplay";
import embedLogo from "../../../assets/single_embed_logo.svg";

interface HotspotPopupProps {
  data: any;
  onClose: () => void;
  onInvestigate: (caseId: string) => void;
}

const HotspotPopup: React.FC<HotspotPopupProps> = ({
  data,
  onClose,
  onInvestigate,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[4],
        borderRadius: theme.shape.borderRadius,
        width: "300px",
        zIndex: 1000,
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: theme.spacing(2),
          backgroundColor: theme.palette.background.default,
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <SeverityIndicator severity={data.severity} />
        <Button
          onClick={onClose}
          sx={{
            minWidth: "auto",
            color: theme.palette.text.secondary,
            fontSize: "1.2rem",
            lineHeight: 1,
            "&:hover": { color: theme.palette.text.primary },
          }}
        >
          ×
        </Button>
      </Box>

      {/* Title */}
      <Box sx={{ padding: theme.spacing(2) }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {data.title}
        </Typography>
      </Box>

      {/* Details */}
      <Box>
        {[
          { label: "Created At", value: data.createdAt || "N/A" },
          { label: "Alert Time", value: data.alertTimestamp || "N/A" },
          { label: "Device", value: data.device || "N/A" },
          { label: "Conclusion", value: data.conclusion || "N/A" },
          { label: "MITRE Stage", value: data.mitreStage || "N/A" },
          {
            label: "Feedback",
            value: data.feedback || "N/A",
          },
          {
            label: "Analyst",
            value: (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  alt={data.analyst}
                  src={
                    data.analyst === "Embed"
                      ? embedLogo
                      : `https://i.pravatar.cc/40?u=${data.analyst}`
                  }
                  sx={{
                    marginRight: theme.spacing(1),
                    height: theme.spacing(3),
                    width: theme.spacing(3),
                  }}
                />
                <Typography variant="body2">{data.analyst || "N/A"}</Typography>
              </Box>
            ),
          },
        ].map((item, index) => (
          <React.Fragment key={index}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: theme.spacing(1, 2),
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: theme.palette.grey[600] }}
              >
                {item.label}:
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, textAlign: "right" }}
              >
                {item.value}
              </Typography>
            </Box>
            {index < 6 && <Divider />}
          </React.Fragment>
        ))}
      </Box>

      {/* Investigate Button */}
      <Box sx={{ padding: theme.spacing(2) }}>
        <Button
          variant="contained"
          onClick={() => onInvestigate(data.caseId)}
          sx={{
            width: "100%",
            backgroundColor: theme.palette.info.main,
            "&:hover": {
              backgroundColor: theme.palette.info.dark,
            },
          }}
        >
          Investigate
        </Button>
      </Box>
    </Box>
  );
};

export default HotspotPopup;
