import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchCaseDetail = createAsyncThunk(
  "caseDetail/fetchCaseDetail",
  async (
    { accessToken, caseId }: { accessToken: string; caseId: string },
    { rejectWithValue }
  ) => {
    if (!accessToken) {
      return rejectWithValue("User is not authenticated");
    }
    const apiUrl = `${process.env.REACT_APP_CASE_DETAILS_URL}/${caseId}`; // Append caseId to URL

    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        // Parse error response
        const errorData = await response.json();
        return rejectWithValue(
          errorData.message || "Failed to fetch case details"
        );
      }

      const data = await await response.json();
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to fetch case detail");
    }
  }
);

// New submitFeedback action
export const submitFeedback = createAsyncThunk<
  { position: "agree" | "disagree"; reason: string }, // Return type
  {
    accessToken: string;
    caseId: string;
    position: "agree" | "disagree";
    reason?: string;
  }, // Argument type
  { rejectValue: string }
>(
  "caseDetail/submitFeedback",
  async (
    { accessToken, caseId, position, reason = "User provided feedback" },
    { rejectWithValue }
  ) => {
    if (!accessToken) {
      return rejectWithValue("User is not authenticated");
    }

    const apiUrl = `${process.env.REACT_APP_CASE_DETAILS_FEEDBACK}/${caseId}/feedback`;

    const body = {
      position,
      reason,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        let errorMessage = "Failed to submit feedback";
        try {
          const errorData = await response.json();
          errorMessage = errorData.message || errorMessage;
        } catch (err) {
          // If response is empty or not JSON, keep the default error message
        }
        return rejectWithValue(errorMessage);
      }

      // Since the response body is empty, return the feedback we submitted
      return { position, reason };
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to submit feedback");
    }
  }
);

export const fetchEmlData = createAsyncThunk<
  string, // Return type for the fulfilled action
  { accessToken: string; caseId: string }, // Argument type
  { rejectValue: string } // Rejected action type
>(
  "caseDetail/fetchEmlData",
  async ({ accessToken, caseId }, { rejectWithValue }) => {
    const apiUrl = `${process.env.REACT_APP_CASE_DETAILS_EML}/${caseId}`;
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.text(); // Assuming server returns error as text
        return rejectWithValue(errorData || "Failed to fetch .eml data");
      }

      const data = await response.text(); // Get the email data as text
      return data;
    } catch (error: any) {
      return rejectWithValue(
        error.message || "An unexpected error occurred while fetching .eml data"
      );
    }
  }
);
