import { createTheme, PaletteMode } from "@mui/material/styles";

// Common palette properties
const commonPalette = {
  common: {
    white: "#FFFFFF",
    black: "#000000",
  },
  primary: {
    main: "#705FFF",
    light: "#7986cb",
    dark: "#303f9f",
  },
  secondary: {
    main: "#416BFF",
    light: "#FF708B",
    dark: "#f50057",
  },
  error: {
    main: "#DF3232",
    light: "#FFEBEB",
    dark: "#9D2121",
  },
  warning: {
    main: "#F6BB22",
    light: "#FFF4D6",
    dark: "#C89A18",
  },
  info: {
    main: "#079FFA",
    light: "#E6F4FF",
    dark: "#0277BD",
  },
  success: {
    main: "#09B898",
    light: "#E0F7F4",
    dark: "#047D66",
  },
  grey: {
    100: "#f5f5f5",
    200: "#eeeeee",
    300: "#e0e0e0",
    400: "#bdbdbd",
    500: "#9e9e9e",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
  },
};

// Light Theme
const lightTheme = createTheme({
  palette: {
    mode: "light" as PaletteMode,
    ...commonPalette,
    background: {
      default: "#f5f5f5",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#000000",
      secondary: "#424242",
    },
  },
  typography: {
    fontFamily: '"Inter", "Montserrat", "Helvetica", "Arial", sans-serif',
    h3: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: "2rem",
    },
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        root: {
          "& .MuiBadge-badge:not(.MuiBadge-dot)": {
            backgroundColor: "#E6F4FF",
            color: "#29A5FF",
            fontWeight: "bold",
            padding: "0 4px",
            fontSize: "0.75rem",
            borderRadius: "4px",
            minWidth: "20px",
            top: "-5px",
            right: "-15px",
          },
        },
      },
    },
    // TODO: Add other component overrides here
  },
});

// Dark Theme
const darkTheme = createTheme({
  palette: {
    mode: "dark" as PaletteMode,
    ...commonPalette,
    background: {
      default: "#121212",
      paper: "#1D1D1D",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#B0B0B0",
    },
  },
  typography: {
    fontFamily: '"Inter", "Montserrat", "Helvetica", "Arial", sans-serif',
    h3: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: "2rem",
    },
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        root: {
          "& .MuiBadge-badge:not(.MuiBadge-dot)": {
            backgroundColor: "#333333",
            color: "#FF708B",
            fontWeight: "bold",
            padding: "0 4px",
            fontSize: "0.75rem",
            borderRadius: "4px",
            minWidth: "20px",
            top: "-5px",
            right: "-15px",
          },
        },
      },
    },
    // TODO: Add other component overrides here
  },
});

// Sepia Theme
const sepiaTheme = createTheme({
  palette: {
    mode: "light" as PaletteMode, // Sepia is a variant of light mode
    ...commonPalette,
    background: {
      default: "#F4ECD8",
      paper: "#FFF8E1",
    },
    text: {
      primary: "#5B4636",
      secondary: "#7B6B56",
    },
    primary: {
      main: "#C8AD7F",
      light: "#DDB67D",
      dark: "#A79863",
    },
    secondary: {
      main: "#D9B382",
      light: "#EAC086",
      dark: "#BFA46C",
    },
  },
  typography: {
    fontFamily: '"Inter", "Montserrat", "Helvetica", "Arial", sans-serif',
    h3: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: "2rem",
    },
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        root: {
          "& .MuiBadge-badge:not(.MuiBadge-dot)": {
            backgroundColor: "#DDE2C6",
            color: "#A79863",
            fontWeight: "bold",
            padding: "0 4px",
            fontSize: "0.75rem",
            borderRadius: "4px",
            minWidth: "20px",
            top: "-5px",
            right: "-15px",
          },
        },
      },
    },
    // TODO: Add other component overrides here
  },
});

// High-Contrast Theme
const highContrastTheme = createTheme({
  palette: {
    mode: "light" as PaletteMode, // High-contrast is a variant; could also use dark
    ...commonPalette,
    primary: {
      main: "#000000", // Black
      light: "#FFFFFF", // White for high contrast
      dark: "#000000",
    },
    secondary: {
      main: "#FFFF00", // Yellow
      light: "#FFFF00",
      dark: "#FFFF00",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#000000",
      secondary: "#FFFF00",
    },
  },
  typography: {
    fontFamily: '"Arial Black", Gadget, sans-serif', // Bold fonts for better readability
    h3: {
      fontFamily: '"Arial Black", Gadget, sans-serif',
      fontWeight: 900,
      fontSize: "2rem",
    },
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        root: {
          "& .MuiBadge-badge:not(.MuiBadge-dot)": {
            backgroundColor: "#FFFF00",
            color: "#000000",
            fontWeight: "bold",
            padding: "0 4px",
            fontSize: "0.75rem",
            borderRadius: "0px", // Sharp edges for high contrast
            minWidth: "20px",
            top: "-5px",
            right: "-15px",
          },
        },
      },
    },
    // TODO: Add other component overrides here
  },
});

export { lightTheme, darkTheme, sepiaTheme, highContrastTheme };
