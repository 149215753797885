import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCaseDetail,
  fetchEmlData,
  submitFeedback,
} from "../../../api/caseDetailsApi";
import { CaseDetails, Feedback } from "../../../types/caseDetailsTypes";

interface CaseDetailState {
  caseDetail: CaseDetails | null;
  loading: boolean;
  error: string | null;
  feedbackLoading: boolean;
  feedbackError: string | null;
  emlData: string | null; // Stores fetched .eml data
  emlLoading: boolean; // Tracks loading state for .eml data
  emlError: string | null; // Stores errors related to .eml fetching
}

const initialState: CaseDetailState = {
  caseDetail: null,
  loading: false,
  error: null,
  feedbackLoading: false,
  feedbackError: null,
  emlData: null,
  emlLoading: false,
  emlError: null,
};

const caseDetailSlice = createSlice({
  name: "caseDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Existing case detail fetching actions
      .addCase(fetchCaseDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.feedbackError = null; // Reset feedbackError here
      })
      .addCase(fetchCaseDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.caseDetail = action.payload;
      })
      .addCase(fetchCaseDetail.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.error && action.error.message) ||
          "Failed to fetch case detail";
      })
      // Feedback submission actions
      .addCase(submitFeedback.pending, (state) => {
        state.feedbackLoading = true;
        state.feedbackError = null;
      })
      .addCase(submitFeedback.fulfilled, (state, action) => {
        state.feedbackLoading = false;
        const { position, reason } = action.payload;

        if (state.caseDetail) {
          if (
            state.caseDetail.feedbacks &&
            state.caseDetail.feedbacks.length > 0
          ) {
            // Update the existing feedback entry
            const feedbackEntry = state.caseDetail.feedbacks[0];
            feedbackEntry.result = position;
            feedbackEntry.text = reason;
            // Retain existing user and updatedAt values
          } else {
            // No existing feedback, create a new one (if necessary)
            const feedbackEntry: Feedback = {
              user: {
                name: state.caseDetail.analyst || "Unknown", // Use existing analyst name or 'Unknown'
              },
              updatedAt: state.caseDetail.updatedAt || new Date().toISOString(), // Use existing updatedAt or current time
              result: position,
              text: reason,
            };
            state.caseDetail.feedbacks = [feedbackEntry];
          }
        }
      })

      .addCase(submitFeedback.rejected, (state, action) => {
        state.feedbackLoading = false;
        state.feedbackError =
          action.payload || action.error.message || "Failed to submit feedback";
      })
      // EML fetching actions
      .addCase(fetchEmlData.pending, (state) => {
        state.emlLoading = true;
        state.emlError = null;
      })
      .addCase(fetchEmlData.fulfilled, (state, action) => {
        state.emlLoading = false;
        state.emlData = action.payload; // Store the fetched .eml data
      })
      .addCase(fetchEmlData.rejected, (state, action) => {
        state.emlLoading = false;
        state.emlError = action.payload || "Failed to fetch .eml data";
      });
  },
});

export default caseDetailSlice.reducer;
