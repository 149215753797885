import React, { useState } from "react";
import { NavLink, useLocation, matchPath } from "react-router-dom";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemIcon,
  Popover,
  Typography,
  Button,
  Divider,
  Paper,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import EmbedLogo from "../assets/single_embed_logo.svg";
import CaseListIconSVG from "../assets/case_list_icon.svg";
import CaseDetailIconSVG from "../assets/case_detail.svg";
import ArdavanAvatar from "../assets/avatar_ardavan_golshan.svg";
import ThemeToggle from "../common/components/ThemeToggle";
import DashboardIconSVG from "../assets/dashboard_icon.svg";
import TableListSVG from "../assets/table_icon.svg";
import { FaTable } from "react-icons/fa";

const Sidebar = () => {
  const theme = useTheme();
  const { user, logout } = useAuth0(); // Use Auth0 to get user info and logout function
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // For managing the Popover
  const location = useLocation();

  const isLightTheme = theme.palette.mode === "light";

  // Check if the current path matches /cases/:id
  const isCaseDetailActive = matchPath(
    { path: "/cases/:id", end: false },
    location.pathname
  );

  // Shared styles for all list items
  const listItemStyles = {
    textDecoration: "none",
    color: "inherit",
    position: "relative",
    justifyContent: "center",
    fontSize: "0.875rem", // Optional: smaller font size for text
    marginBottom: "2.5rem",
    "&.active": {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
      "& img": {
        transform: "scale(1.2)",
        transition: "transform 0.3s ease",
      },
    },
  };

  // Icon size and color styling
  const iconStyles = {
    filter: `grayscale(100%) brightness(70%)`,
    "& img": {
      transition: "transform 0.3s ease",
    },
    "&:hover img": {
      transform: "scale(1.5)",
    },
    "&.active:hover img": {
      transform: "scale(1.5)",
    },
  };

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget); // Open the Popover when the avatar is clicked
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the Popover
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-info-popover" : undefined;

  return (
    <Paper
      sx={{
        width: 80,
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "2rem",
        boxShadow: "2px 0 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    >
      {/* Top - Embed Company Logo */}
      <Box>
        <img
          src={EmbedLogo}
          alt="Embed Company Logo"
          style={{ width: "40px", marginTop: "1em", marginBottom: "1em" }}
        />
      </Box>

      {/* Middle - Navigation Icons */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <List>
          {/* TODO: Reintegrate after dashboard is complete */}
          <ListItem
            component={NavLink}
            to="/dashboard"
            sx={listItemStyles}
            disablePadding
          >
            <ListItemIcon
              sx={{ justifyContent: "center", color: "inherit", ...iconStyles }}
            >
              <img src={DashboardIconSVG} alt="Dashboard Icon" />
            </ListItemIcon>
          </ListItem>

          {/* Cases List Item */}
          <ListItem
            component={NavLink}
            to="/cases"
            end
            sx={listItemStyles}
            disablePadding
          >
            <ListItemIcon
              sx={{ justifyContent: "center", color: "inherit", ...iconStyles }}
            >
              <img src={TableListSVG} alt="Table List Icon" />
            </ListItemIcon>
          </ListItem>

          {/* Case Detail List Item Non-Clickable */}
          <ListItem
            sx={{
              ...listItemStyles,
              ...(isCaseDetailActive && {
                borderLeft: `4px solid ${theme.palette.primary.main}`,
              }),
              pointerEvents: "none", // Makes this item non-clickable
              cursor: "default",
            }}
            disablePadding
          >
            <ListItemIcon
              sx={{ justifyContent: "center", color: "inherit", ...iconStyles }}
            >
              <img src={CaseDetailIconSVG} alt="Case Detail Icon" />
            </ListItemIcon>
          </ListItem>
        </List>
      </Box>

      {/* Bottom - User Avatar */}
      <Box
        sx={{ marginBottom: "20px", display: "flex", justifyContent: "center" }}
      >
        <Avatar
          src={user?.picture || ArdavanAvatar} // Replace with dynamic user avatar
          alt="User Avatar"
          sx={{
            width: 40,
            height: 40,
            cursor: "pointer",
            justifyContent: "center",
          }}
          onClick={handleAvatarClick} // Open Popover on click
        />
        {/* Popover for User Information */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Box
            sx={{
              padding: "16px",
              textAlign: "center",
              minWidth: "200px",
            }}
          >
            <Avatar
              src={user?.picture || ArdavanAvatar} // Replace with dynamic user avatar
              alt="User Avatar"
              sx={{
                width: 60,
                height: 60,
                margin: "0 auto 8px auto",
                justifyContent: "center",
              }}
            />
            <Typography variant="h6">{user?.name || "User"}</Typography>
            <Typography variant="body2" color="textSecondary">
              {user?.email || "user@example.com"}
            </Typography>
            <Divider sx={{ my: 2 }} />
            {/* Theme Toggle */}
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Theme Settings
              </Typography>
              <ThemeToggle />
            </Box>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: "12px" }}
              onClick={() =>
                logout({
                  logoutParams: {
                    returnTo: window.location.origin,
                  },
                })
              }
            >
              Log Out
            </Button>
          </Box>
        </Popover>
      </Box>
    </Paper>
  );
};

export default Sidebar;
