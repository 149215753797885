import { SymmetricPolygon, CompositePolygon } from "./ui_classes";
import { alpha } from "@mui/material/styles"; // Import alpha for opacity

// Any color below can take on rgba or hex values

// Each symmetric polygon is defined by (#num of sides, line_width, line_color, fill_color, hover_line_color, hover_fill_color)
// So, switching to a different style set for hovering over a polygon is done automatically
// Composite polygons just draw symmetric polygons over each other at the sizes specified in the following array

// Wouldn't recommend setting the line_width to zero, even if you don't want an outline
// Instead change the line_color to be the same as the fill color
export const ui_shapes = (theme) => ({
  "To Me": new SymmetricPolygon(
    3,
    2,
    theme.palette.primary.main,
    alpha(theme.palette.primary.light, 0.3), // 30% opacity
    theme.palette.primary.main,
    alpha(theme.palette.primary.light, 0.5) // 50% opacity
  ),
  "To Others": new SymmetricPolygon(
    3,
    2,
    theme.palette.secondary.main,
    alpha(theme.palette.secondary.light, 0.3),
    theme.palette.secondary.main,
    alpha(theme.palette.secondary.light, 0.5)
  ),
  Unassigned: new SymmetricPolygon(
    3,
    2,
    theme.palette.grey[500],
    alpha(theme.palette.grey[500], 0.3),
    theme.palette.grey[500],
    alpha(theme.palette.grey[500], 0.5)
  ),
  "Show All": new SymmetricPolygon(
    3,
    2,
    theme.palette.grey[500],
    alpha(theme.palette.grey[500], 0.3),
    theme.palette.grey[500],
    alpha(theme.palette.grey[500], 0.5)
  ),
  Reviewed: new SymmetricPolygon(
    3,
    2,
    "#77C0F4",
    "rgba(119, 192, 244, 0.3)",
    "#77C0F4",
    "rgba(119, 192, 244, 0.5)"
  ),
  Unreviewed: new SymmetricPolygon(
    3,
    2,
    "#A196F7",
    "rgba(161, 150, 247, 0.3)",
    "#A196F7",
    "rgba(161, 150, 247, 0.5)"
  ),
  "Unselected Radio": new SymmetricPolygon(
    0,
    1,
    "rgba(223, 244, 251, 1)",
    "rgba(223, 244, 251, 1)",
    "rgba(223, 244, 251, 1)",
    "rgba(192, 234, 248, 1)"
  ),
  "Selected Radio": new CompositePolygon(
    [
      new SymmetricPolygon(
        0,
        2,
        "rgba(224, 225, 232, 1)",
        "rgba(254, 253, 255, 1)",
        "rgba(224, 225, 232, 1)",
        "rgba(254, 253, 255, 1)"
      ),
      new SymmetricPolygon(
        0,
        0,
        "rgba(94, 64, 246, 1.0)",
        "rgba(94, 64, 246, 1.0)",
        "rgba(94, 64, 246, 1.0)",
        "rgba(94, 64, 246, 1.0)"
      ),
      new SymmetricPolygon(
        0,
        0,
        "rgba(254, 253, 255, 1)",
        "rgba(254, 253, 255, 1)",
        "rgba(254, 253, 255, 1)",
        "rgba(254, 253, 255, 1)"
      ),
    ],
    [1, 0.6, 0.16]
  ),
  "To Me Selected": new CompositePolygon(
    [
      new SymmetricPolygon(
        3,
        2,
        theme.palette.primary.main,
        alpha(theme.palette.primary.light, 0.0), // Fully transparent
        theme.palette.primary.main,
        alpha(theme.palette.primary.light, 0.0)
      ),
      new SymmetricPolygon(
        3,
        2,
        theme.palette.primary.main,
        alpha(theme.palette.primary.light, 0.3),
        theme.palette.primary.main,
        alpha(theme.palette.primary.light, 0.3)
      ),
    ],
    [1, 0.6]
  ),
  "To Others Selected": new CompositePolygon(
    [
      new SymmetricPolygon(
        3,
        2,
        theme.palette.secondary.main,
        alpha(theme.palette.secondary.light, 0.0),
        theme.palette.secondary.main,
        alpha(theme.palette.secondary.light, 0.0)
      ),
      new SymmetricPolygon(
        3,
        2,
        theme.palette.secondary.main,
        alpha(theme.palette.secondary.light, 0.3),
        theme.palette.secondary.main,
        alpha(theme.palette.secondary.light, 0.3)
      ),
    ],
    [1, 0.6]
  ),
  "Unassigned Selected": new CompositePolygon(
    [
      new SymmetricPolygon(
        3,
        2,
        theme.palette.grey[500],
        alpha(theme.palette.grey[500], 0.0),
        theme.palette.grey[500],
        alpha(theme.palette.grey[500], 0.0)
      ),
      new SymmetricPolygon(
        3,
        2,
        theme.palette.grey[500],
        alpha(theme.palette.grey[500], 0.3),
        theme.palette.grey[500],
        alpha(theme.palette.grey[500], 0.3)
      ),
    ],
    [1, 0.6]
  ),
  "Show All Selected": new CompositePolygon(
    [
      new SymmetricPolygon(
        3,
        2,
        theme.palette.grey[500],
        alpha(theme.palette.grey[500], 0.0),
        theme.palette.grey[500],
        alpha(theme.palette.grey[500], 0.0)
      ),
      new SymmetricPolygon(
        3,
        2,
        theme.palette.grey[500],
        alpha(theme.palette.grey[500], 0.3),
        theme.palette.grey[500],
        alpha(theme.palette.grey[500], 0.3)
      ),
    ],
    [1, 0.6]
  ),
  "Reviewed Selected": new CompositePolygon(
    [
      new SymmetricPolygon(
        3,
        2,
        "#77C0F4",
        "rgba(119, 192, 244, 0.0)",
        "#77C0F4",
        "rgba(119, 192, 244, 0.0)"
      ),
      new SymmetricPolygon(
        3,
        2,
        "#77C0F4",
        "rgba(119, 192, 244, 0.3)",
        "#77C0F4",
        "rgba(119, 192, 244, 0.3)"
      ),
    ],
    [1, 0.6]
  ),
  "Unreviewed Selected": new CompositePolygon(
    [
      new SymmetricPolygon(
        3,
        2,
        "#A196F7",
        "rgba(161, 150, 247, 0)",
        "#A196F7",
        "rgba(161, 150, 247, 0)"
      ),
      new SymmetricPolygon(
        3,
        2,
        "#A196F7",
        "rgba(161, 150, 247, 0.3)",
        "#A196F7",
        "rgba(161, 150, 247, 0.3)"
      ),
    ],
    [1, 0.6]
  ),
});

export const ui_global_settings = (theme) => ({
  min_canvas_width: 800, // in pixels
  aspect_ratio: 1.5, // canvas width/height
  screen_fraction_used: 0.9, // percent of canvas_height the dashboard uses
  font_name: theme.typography.fontFamily, // Use theme font family
  dashboard_background_color: theme.palette.background.default, // Background color from theme

  // For canvas debugging (turn this off for product release)
  draw_canvas_outline: false,
  canvas_outline_color: theme.palette.divider,

  // Radio arc (the curved line below the time filter radio buttons)
  draw_radio_arc: true,
  radio_arc_color: "rgba(228, 242, 254, 1)",
  radio_arc_start_ang: 0, // Needs to be intersected first when rotating clockwise
  radio_arc_end_ang: 60, // Compared to this angle
  radio_arc_width: 4, // All angles above, including this one, are in degrees

  // Title-ring properties
  title_ring_font_size: 0.35, // A proportion of the title ring's (outer-inner radius)
  title_ring_side_margin: 2, // In degrees
  title_ring_color: theme.palette.mode === "light" ? "#D6DCFF90" : "#7986cb",
  title_ring_font_weight: theme.typography.h3.fontWeight || "bold", // Use theme font weight
  title_ring_font_color: theme.palette.text.primary,
  title_ring_line_color: theme.palette.divider,
  title_ring_line_width: 2,

  // Segment graph properties
  graph_line_width: 3, // In pixels
  graph_line_color:
    theme.palette.mode === "light" ? "#29A5FF" : theme.palette.primary.main,
  graph_fill_color:
    theme.palette.mode === "light"
      ? `#29A5FF40`
      : `${theme.palette.primary.light}70`, // Adding opacity (50%)
  graph_resolution: 1.0, // Goes from 0->jagged lines to 1->smooth curves
  graph_points: false,
  graph_fill: true,
  point_size: 4, // In pixels
  point_color: theme.palette.primary.main,

  // Graph margins
  margin_between_graphs: 2, // Angle in degrees
  title_ring_margin: 0.03, // Margin between end of graph ring and start of title ring
  graph_top_margin: 0.02, // Margin between end of graph ring and top of graph

  // Center properties
  center_label_font_color: theme.palette.text.secondary,
  center_label_1_txt: "Escalated Cases",
  center_label_2_txt: "Denoised Cases",
  center_label_font_weight: theme.typography.fontWeightBold || "bold", // Use theme font weight
  center_number_font_color: theme.palette.text.primary,
  center_number_font_weight: theme.typography.fontWeightBold || "bold", // Use theme font weight
  number_to_label_ratio: 2.5,
  center_label_font_size: 0.15, // Proportion of inner ring radius
  center_label_number_margin: 0.05, // Proportion of inner ring radius
  center_label_margin: 0.11, // Proportion of inner ring radius

  // Labels
  general_label_color: theme.palette.text.secondary,
  selected_label_color: theme.palette.text.primary,
  label_font_weight: theme.typography.fontWeightBold || "bold", // Use theme font weight

  // Lines
  line_segmentation_color: theme.palette.divider,
  line_segmentation_width: 2,
  number_of_dashed_lines: 4,
  dash_density: 200, // Dashes per inner_graph_ring
  dash_size: [1, 1], // Numbers only used for relativity

  // Center/inner ring, hotspot ring, graph ring, [0.9->1] title ring, radio button ring
  ring_distribution: [0.22, 0.6, 0.9, 1.1],

  // Rotation properties
  animation_ease_exponent: 1.7, // 1<=x<=2 ==> 1->linear, 2->very smooth easing
  animation_duration: 1.5, // In seconds
  animation_fps: 120,

  // Hotspot dispersal properties
  num_per_band: 50, // Have to manage this
  band_distribution: [0.25, 0.6], // Proportion of hotspot ring radius where medium and large hotspots start
  band_margin: 0.05, // To ensure hotspots don't go over band lines
  radius_margin: 0.1, // To ensure hotspots don't intersect
  general_label_color: theme.palette.text.secondary, // Update labels for hotspot filters
  selected_label_color: theme.palette.text.primary, // Update labels for hotspot filters
});
