import React from "react";
import { Box, Skeleton } from "@mui/material";

const EmbedCircleSkeleton: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Skeleton variant="circular" width={1000} height={1000} />
    </Box>
  );
};

export default EmbedCircleSkeleton;
