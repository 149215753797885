import React from "react";
import { Box } from "@mui/material";
import LoadingAnimation from "../../../common/components/LoadingAnimation";

interface EmailImageSectionProps {
  emailImageUrl: string;
  imageData: string | null;
  error: string | null;
}

const EmailImageSection: React.FC<EmailImageSectionProps> = ({
  emailImageUrl,
  imageData,
  error,
}) => {
  if (!imageData && !error) {
    return (
      <div style={{ width: "100%", height: "50vh" }}>
        <LoadingAnimation />
      </div>
    );
  }

  if (error) {
    return <Box>{error}</Box>;
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "8px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
      }}
    >
      {imageData ? (
        <img
          src={imageData}
          alt="Email Snapshot"
          style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: "8px" }}
        />
      ) : (
        <Box>Error loading image</Box>
      )}
    </Box>
  );
};

export default EmailImageSection;
