import React from "react";
import { Handle, Position } from "@xyflow/react";
import { useTheme, Box, Typography } from "@mui/material";

// MUI Icons
import ReportIcon from "@mui/icons-material/Report";
import PublicIcon from "@mui/icons-material/Public";
import EmailIcon from "@mui/icons-material/Email";
import TitleIcon from "@mui/icons-material/Title";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import LinkIcon from "@mui/icons-material/Link";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { AttachEmail, Dns, Http, Phishing, Send } from "@mui/icons-material";

// Mapping from 'kind' to MUI Icons
const kindToIconMap: { [key: string]: JSX.Element } = {
  UserPhishingReport: <ReportIcon />,
  "User Phishing Report": <Phishing />,
  IPAddress: <Dns />,
  "IP Address": <Dns />,
  emailAddress: <EmailIcon />,
  "Email Address": <EmailIcon />,
  Title: <TitleIcon />,
  Attachment: <AttachFileIcon />,
  "Email Attachment": <AttachEmail />,
  URL: <Http />,
  "Email URL": <Http />,
  "Sender IP Address": <Dns />,
  "To Email Addresses": <Send />,
  "From Email Address": <EmailIcon />,
  "Email Body": <TextFieldsIcon />,
  Default: <HelpOutlineIcon />, // Fallback icon
};

interface CustomNodeProps {
  data: {
    decision: string;
    label: string;
    kind: string;
    timestamp: string;
    isTrigger?: boolean;
  };
}

const CustomNode: React.FC<CustomNodeProps> = React.memo(({ data }) => {
  const theme = useTheme();
  const isRootAlert = data.isTrigger;
  const isMalicious = data.decision === "Malicious";
  const IconComponent = kindToIconMap[data.kind] || kindToIconMap["Default"];

  // Shimmer keyframes
  const shimmerKeyframes = `
    @keyframes shimmer {
      0% {
        background-position: -200% 0;
      }
      100% {
        background-position: 200% 0;
      }
    }
  `;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      {/* Inject keyframes for shimmer effect */}
      <style>{shimmerKeyframes}</style>

      {/* Display Name Above the Node */}
      <Typography
        variant="caption"
        sx={{
          position: "absolute",
          top: -20,
          fontSize: 14,
          fontWeight: "bold",
          textAlign: "center",
          whiteSpace: "nowrap",
          color: theme.palette.text.primary,
        }}
      >
        {isRootAlert ? "Root Alert" : data.label}
      </Typography>

      {/* Kind Label Below the Display Name */}
      <Typography
        variant="caption"
        sx={{
          fontSize: 10,
          color: theme.palette.text.secondary,
          marginTop: 1,
          marginBottom: 1,
        }}
      >
        {data.kind}
      </Typography>

      {/* Node Circle with Icon */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor:
            isMalicious || isRootAlert
              ? theme.palette.error.main
              : theme.palette.background.default,
          border: `2px solid ${
            isMalicious || isRootAlert
              ? theme.palette.error.dark
              : theme.palette.divider
          }`,
          borderRadius: "50%",
          width: 120,
          height: 120,
          boxShadow: theme.shadows[3],
          position: "relative",
          overflow: "hidden",
          "&::before": {
            ...(isMalicious || isRootAlert
              ? {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundImage: `linear-gradient(
                    90deg,
                    transparent,
                    rgba(255, 255, 255, 0.3),
                    transparent
                  )`,
                  backgroundSize: "200% 100%",
                  backgroundPosition: "-200% 0",
                  animation: `shimmer 2s infinite`,
                  zIndex: 1,
                }
              : {}),
          },
        }}
      >
        {/* Icon Inside the Node */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 60,
            height: 60,
            color:
              isMalicious || isRootAlert
                ? theme.palette.error.contrastText
                : theme.palette.text.primary,
            position: "relative",
            zIndex: 2,
          }}
        >
          {IconComponent}
        </Box>
      </Box>

      {/* Timestamp Below the Node */}
      <Typography
        variant="caption"
        sx={{
          marginTop: 1,
          fontSize: 10,
          color: theme.palette.text.secondary,
        }}
      >
        {data.timestamp}
      </Typography>

      {/* Connection Handles */}
      <Handle
        type="target"
        position={Position.Left}
        style={{
          background: theme.palette.primary.main,
          border: `1px solid ${theme.palette.divider}`,
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: theme.palette.primary.main,
          border: `1px solid ${theme.palette.divider}`,
        }}
      />
    </Box>
  );
});

export default CustomNode;
