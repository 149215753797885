import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/rootReducer";

interface User {
  name: string;
  email: string;
  picture: string;
}

interface AuthState {
  user: User | null;
  token: string | null;
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  token: null,
  isAuthenticated: false,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthState(state, action: PayloadAction<Partial<AuthState>>) {
      state.user = action.payload.user || state.user;
      state.token = action.payload.token || state.token;
      state.isAuthenticated =
        action.payload.isAuthenticated ?? state.isAuthenticated;
      state.loading = action.payload.loading ?? state.loading;
      state.error = action.payload.error ?? state.error;
    },
    logout(state) {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      state.loading = false;
      state.error = null;
    },
    setAuthLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setAuthError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
  },
});

export const { setAuthState, logout, setAuthLoading, setAuthError } =
  authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;
export const selectUser = (state: RootState) => state.auth.user;
export const selectAuthToken = (state: RootState) => state.auth.token;
export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;

export default authSlice.reducer;
