import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Container } from "@mui/system";
import { CircularProgress, Typography } from "@mui/material";

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  // Show a loading indicator or just null until Auth0 finishes loading
  if (isLoading) {
    return (
      <Container
        maxWidth="sm"
        style={{ textAlign: "center", marginTop: "100px" }}
      >
        <CircularProgress />
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Checking login status...
        </Typography>
      </Container>
    );
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
