import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MdSignalCellular1Bar from "@mui/icons-material/SignalCellular1Bar";
import MdSignalCellular2Bar from "@mui/icons-material/SignalCellular2Bar";
import MdSignalCellular3Bar from "@mui/icons-material/SignalCellular3Bar";
import MdSignalCellular4Bar from "@mui/icons-material/SignalCellular4Bar";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

interface SeverityIndicatorProps {
  severity: string;
}

const SeverityIndicator: React.FC<SeverityIndicatorProps> = ({ severity }) => {
  const theme = useTheme();

  // Map severity to icon and color
  const getSeverityDetails = (level: string) => {
    switch (level) {
      case "Critical":
        return {
          icon: <MdSignalCellular4Bar />,
          color: `${theme.palette.error.main}`,
        }; // Red
      case "High":
        return {
          icon: <MdSignalCellular3Bar />,
          color: `${theme.palette.warning.dark}`,
        }; // Red
      case "Medium":
        return {
          icon: <MdSignalCellular2Bar />,
          color: `${theme.palette.warning.main}`,
        }; // Yellow
      case "Low":
        return {
          icon: <MdSignalCellular1Bar />,
          color: `${theme.palette.grey[600]}`,
        }; // Yellow
      default:
        return {
          icon: <HelpOutlineIcon />,
          color: theme.palette.text.secondary,
        }; // Default color for unknown severity
    }
  };

  const { color } = getSeverityDetails(severity);

  return (
    <Box sx={{ display: "flex", alignItems: "center", color }}>
      {/* {icon} */}
      <Typography variant="body2" sx={{ marginLeft: 1 }}>
        {severity}
      </Typography>
    </Box>
  );
};

export default SeverityIndicator;
