import React from "react";
import { Box, Typography, Chip } from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";

interface FeedbackDisplayProps {
  feedback: string | null;
}

const FeedbackDisplay: React.FC<FeedbackDisplayProps> = ({ feedback }) => {
  const isAgree = feedback === "agree";

  return (
    <Chip
      label={
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.8 }}>
          {isAgree ? (
            <ThumbUpAltIcon sx={{ fontSize: 12 }} />
          ) : (
            <ThumbDownAltIcon sx={{ fontSize: 12 }} />
          )}
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", fontSize: "0.75rem" }}
          >
            {feedback}
          </Typography>
        </Box>
      }
      sx={{
        backgroundColor: isAgree ? "success.main" : "error.main",
        color: isAgree ? "success.contrastText" : "error.contrastText",
        fontWeight: "bold",
        padding: "2px 2px",
        borderRadius: "20px",
        height: "30px",
        width: "90px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
      }}
    />
  );
};

export default FeedbackDisplay;
