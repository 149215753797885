import { createSlice } from "@reduxjs/toolkit";
import { fetchCases } from "../../../api/casesApi";
import { Case } from "../types";

interface CasesState {
  cases: Case[];
  loading: boolean;
  error: string | null;
}

const initialState: CasesState = {
  cases: [],
  loading: false,
  error: null,
};

const casesSlice = createSlice({
  name: "cases",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCases.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCases.fulfilled, (state, action) => {
      state.loading = false;
      state.cases = action.payload;
    });
    builder.addCase(fetchCases.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Failed to fetch cases";
    });
  },
});

export default casesSlice.reducer;
