import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  IconButton,
  CircularProgress,
  TextField,
  Typography,
  Popover,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { useTheme } from "@mui/material/styles";

interface FeedbackSectionProps {
  feedback: string | null;
  feedbackReason: string | null;
  feedbackLoading: boolean;
  feedbackError: string | null;
  isEditingFeedback: boolean;
  feedbackOption: string;
  feedbackText: string;
  onFeedbackOptionChange: (
    event: React.MouseEvent<HTMLElement>,
    newOption: string
  ) => void;
  onFeedbackTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmitFeedback: () => void;
  onAgree: (position: "agree", reason: string) => void;
  onDisagree: (position: "disagree", reason: string) => void;
  onEditFeedback: () => void;
  onCancelEdit: () => void;
  setFeedbackText: React.Dispatch<React.SetStateAction<string>>; // Correct type
  setFeedbackOption: React.Dispatch<React.SetStateAction<string>>;
}

const FeedbackSection: React.FC<FeedbackSectionProps> = ({
  feedback,
  feedbackReason,
  feedbackLoading,
  feedbackError,
  isEditingFeedback,
  feedbackOption,
  feedbackText,
  onFeedbackOptionChange,
  onFeedbackTextChange,
  onSubmitFeedback,
  onAgree,
  onDisagree,
  onEditFeedback,
  onCancelEdit,
  setFeedbackText,
  setFeedbackOption,
}) => {
  const theme = useTheme();

  // State for Popover
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleInfoIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInfoIconClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // State for validation
  const [errorText, setErrorText] = useState<string | null>(null);

  // Local state to manage if "Disagree" is selected when there is no feedback
  const [isDisagreeSelected, setIsDisagreeSelected] = useState(false);

  // Handler when "Disagree" is clicked initially
  const handleNewDisagreeClick = () => {
    setFeedbackText("");
    setErrorText(null);
    setIsDisagreeSelected(true);
    setFeedbackOption("disagree");
  };

  const handleNewDisagreeSubmit = () => {
    onDisagree("disagree", "");
  };

  // Handler when "Agree" is clicked initially
  const handleAgreeClick = () => {
    setFeedbackText("");
    setErrorText(null);
    setFeedbackOption("agree");
    onAgree("agree", "");
  };

  const handleFeedbackTextChangeLocal = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    onFeedbackTextChange(event);

    // Simple validation for minimum length
    if (value.trim().length < 5) {
      setErrorText("Reason must be at least 5 characters long.");
    } else {
      setErrorText(null);
    }
  };

  const isSubmitDisabled =
    feedbackLoading || (feedbackText.trim().length < 5 && isDisagreeSelected);

  return (
    <Box>
      {feedback && !isEditingFeedback ? (
        // Existing feedback display
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Chip
            icon={feedback === "agree" ? <ThumbUpIcon /> : <ThumbDownIcon />}
            label={feedback === "agree" ? "Agreed" : "Disagreed"}
            color={feedback === "agree" ? "success" : "error"}
            sx={{
              fontSize: "1rem",
              padding: theme.spacing(1),
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.4)",
              ".MuiChip-icon": {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
              },
            }}
          />
          {feedbackReason && (
            <>
              <Tooltip title="View Feedback" arrow>
                <IconButton onClick={handleInfoIconClick}>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleInfoIconClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Box sx={{ p: 2, minWidth: 500 }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    User Provided Feedback:
                  </Typography>
                  <Typography variant="body1">
                    {feedbackReason && feedbackReason.trim().length > 0
                      ? feedbackReason
                      : "None"}
                  </Typography>
                </Box>
              </Popover>
            </>
          )}
          <Tooltip title="Edit" arrow>
            <IconButton
              onClick={onEditFeedback}
              sx={{ marginLeft: theme.spacing(1) }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ) : isEditingFeedback ? (
        // Edit mode
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(2),
            width: "100%",
          }}
        >
          {/* Toggle for Agree/Disagree */}
          <ToggleButtonGroup
            value={feedbackOption}
            exclusive
            onChange={onFeedbackOptionChange}
            aria-label="feedback option"
          >
            <ToggleButton
              value="agree"
              aria-label="agree"
              sx={{
                color:
                  feedbackOption === "agree"
                    ? "#fff"
                    : theme.palette.text.primary,
                backgroundColor:
                  feedbackOption === "agree"
                    ? theme.palette.success.main
                    : "transparent",
                "&:hover": {
                  backgroundColor:
                    feedbackOption === "agree"
                      ? theme.palette.success.dark
                      : theme.palette.action.hover,
                },
              }}
            >
              <ThumbUpIcon sx={{ marginRight: theme.spacing(1) }} />
              Agree
            </ToggleButton>
            <ToggleButton
              value="disagree"
              aria-label="disagree"
              sx={{
                color:
                  feedbackOption === "disagree"
                    ? "#fff"
                    : theme.palette.text.primary,
                backgroundColor:
                  feedbackOption === "disagree"
                    ? theme.palette.error.main
                    : "transparent",
                "&:hover": {
                  backgroundColor:
                    feedbackOption === "disagree"
                      ? theme.palette.error.dark
                      : theme.palette.action.hover,
                },
              }}
            >
              <ThumbDownIcon sx={{ marginRight: theme.spacing(1) }} />
              Disagree
            </ToggleButton>
          </ToggleButtonGroup>

          {/* Textbox for Feedback Reason */}
          {feedbackOption === "disagree" && (
            <TextField
              label="Reason"
              variant="outlined"
              value={feedbackText}
              onChange={handleFeedbackTextChangeLocal}
              fullWidth
              required
              error={!!errorText}
              helperText={errorText}
              InputLabelProps={{
                style: { color: theme.palette.info.main },
              }}
              sx={{
                minWidth: 500,
              }}
            />
          )}

          {/* Submit and Cancel Buttons */}
          <Button
            variant="contained"
            onClick={onSubmitFeedback}
            disabled={isSubmitDisabled}
            sx={{
              backgroundColor: theme.palette.info.main,
              color: "#fff",
              width: "120px",
              "&:hover": {
                backgroundColor: theme.palette.info.dark,
              },
            }}
          >
            {feedbackLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            variant="text"
            onClick={onCancelEdit}
            disabled={feedbackLoading}
            sx={{
              backgroundColor: "transparent",
              color: theme.palette.text.primary,
              width: "120px",
              "&:hover": {
                backgroundColor: theme.palette.grey[200],
              },
            }}
          >
            Cancel
          </Button>
        </Box>
      ) : isDisagreeSelected ? (
        // Disagree selected when there is no existing feedback
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(2),
            width: "100%",
          }}
        >
          <TextField
            label="Reason"
            variant="outlined"
            value={feedbackText}
            onChange={handleFeedbackTextChangeLocal}
            fullWidth
            required
            error={!!errorText}
            helperText={errorText}
            InputLabelProps={{
              style: { color: theme.palette.info.main },
            }}
            sx={{
              minWidth: 500,
            }}
          />

          {/* Submit and Cancel Buttons */}
          <Button
            variant="contained"
            onClick={handleNewDisagreeSubmit}
            disabled={isSubmitDisabled}
            sx={{
              backgroundColor: theme.palette.info.main,
              color: "#fff",
              width: "120px",
              "&:hover": {
                backgroundColor: theme.palette.info.dark,
              },
            }}
          >
            {feedbackLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            variant="text"
            onClick={() => setIsDisagreeSelected(false)}
            disabled={feedbackLoading}
            sx={{
              backgroundColor: "transparent",
              color: theme.palette.text.primary,
              width: "120px",
              "&:hover": {
                backgroundColor: theme.palette.grey[200],
              },
            }}
          >
            Cancel
          </Button>
        </Box>
      ) : (
        // Initial Agree/Disagree buttons
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.success.main,
              marginRight: "1rem",
              color: "#fff",
              width: "100px",
              fontFamily: "Montserrat",
              "&:hover": {
                backgroundColor: theme.palette.success.dark,
              },
            }}
            onClick={handleAgreeClick}
            disabled={feedbackLoading}
          >
            {feedbackLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "AGREE"
            )}
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.error.main,
              marginRight: "1rem",
              color: "#fff",
              width: "100px",
              fontFamily: "Montserrat",
              "&:hover": {
                backgroundColor: theme.palette.error.dark,
              },
            }}
            onClick={handleNewDisagreeClick}
            disabled={feedbackLoading}
          >
            {feedbackLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "DISAGREE"
            )}
          </Button>
        </Box>
      )}

      {/* Display feedback error if any */}
      {feedbackError && (
        <Typography color="error" sx={{ marginTop: theme.spacing(1) }}>
          {feedbackError}
        </Typography>
      )}
    </Box>
  );
};

export default FeedbackSection;
