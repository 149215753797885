import React from "react";
import {
  Box,
  Button,
  Paper,
  InputBase,
  IconButton,
  Badge,
  ClickAwayListener,
  Popper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTheme } from "@mui/material/styles";
import { Case } from "../types";
import FilterPopup from "./FilterPopup";

interface FiltersSectionProps {
  cases: Case[];
  setFilteredCases: React.Dispatch<React.SetStateAction<Case[]>>;
  activeFilter: string;
  setActiveFilter: React.Dispatch<React.SetStateAction<string>>;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  uniqueConclusions: string[];
  uniqueStatuses: string[];
  uniqueSeverities: string[];
  applyFilters: (filters: any) => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  advancedFilters: any; // Now we get the currently applied filters
}

const countActiveFilters = (filters: any): number => {
  if (!filters) return 0;
  let count = 0;
  if (filters.conclusions && filters.conclusions.length)
    count += filters.conclusions.length;
  if (filters.statuses && filters.statuses.length)
    count += filters.statuses.length;
  if (filters.severities && filters.severities.length)
    count += filters.severities.length;
  if (filters.createdAt && (filters.createdAt[0] || filters.createdAt[1]))
    count++;
  if (
    filters.alertTimestamp &&
    (filters.alertTimestamp[0] || filters.alertTimestamp[1])
  )
    count++;
  if (filters.identities && filters.identities.length)
    count += filters.identities.length;
  return count;
};

const FiltersSection: React.FC<FiltersSectionProps> = ({
  cases,
  setFilteredCases,
  activeFilter,
  setActiveFilter,
  searchTerm,
  setSearchTerm,
  uniqueConclusions,
  uniqueStatuses,
  uniqueSeverities,
  applyFilters,
  setPage,
  advancedFilters,
}) => {
  const theme = useTheme();
  const [isFilterPopupOpen, setIsFilterPopupOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const filterCount = countActiveFilters(advancedFilters);

  // Handle filter popup open/close
  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsFilterPopupOpen((prev) => !prev);
  };

  const handleClose = () => {
    setIsFilterPopupOpen(false);
  };

  // Count of Benign and Escalated cases
  const benignCount = cases.filter(
    (caseItem) => caseItem.conclusion === "Benign"
  ).length;

  const escalatedCount = cases.filter(
    (caseItem) => caseItem.conclusion !== "Benign"
  ).length;

  // Custom styles for filter buttons
  const filterButtonStyles = (status: string) => ({
    color:
      activeFilter === status
        ? theme.palette.text.primary
        : theme.palette.grey[600],
    fontWeight: activeFilter === status ? "bold" : "normal",
    borderBottom:
      activeFilter === status ? `2px solid ${theme.palette.info.main}` : "none",
    textTransform: "none",
    paddingBottom: theme.spacing(1),
    borderRadius: 0, // Ensure edges are not rounded
    minWidth: 0, // Allows the bottom border to span the entire button width
    lineHeight: 1.2, // Adjust line height if needed for aligning text and border
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      outline: "none",
    },
  });

  // Filter functions
  const filterByConclusion = (conclusion: string) => {
    let filteredData;

    if (conclusion === "all") {
      filteredData = cases;
    } else if (conclusion === "Benign") {
      filteredData = cases.filter(
        (caseItem) =>
          caseItem.status === "Auto Closed" || caseItem.conclusion === "Benign"
      );
    } else {
      filteredData = cases.filter(
        (caseItem) =>
          caseItem.status !== "Auto Closed" &&
          (caseItem.status === "Escalated" || caseItem.conclusion !== "Benign")
      );
    }

    setFilteredCases(filteredData);
    setActiveFilter(conclusion);
    setPage(1); // Reset page whenever the filters are applied
  };

  // Search cases by search term (case-insensitive) with live updating
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(2),
      }}
    >
      {/* Quick Filters */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(2),
        }}
      >
        <Button
          onClick={() => filterByConclusion("all")}
          sx={filterButtonStyles("all")}
        >
          All
        </Button>
        <Button
          onClick={() => filterByConclusion("Escalated")}
          sx={filterButtonStyles("Escalated")}
        >
          Escalated
          <Badge badgeContent={escalatedCount} color="primary" />
        </Button>
        <Button
          onClick={() => filterByConclusion("Benign")}
          sx={filterButtonStyles("Benign")}
        >
          Denoised
          <Badge badgeContent={benignCount} color="primary" />
        </Button>
      </Box>

      {/* Search and Filter Icon */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(2),
        }}
      >
        <Paper
          component="form"
          onSubmit={(e) => e.preventDefault()}
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 400,
            boxShadow: "none",
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: "16px", // Rounded edges
          }}
        >
          <SearchIcon
            sx={{ color: theme.palette.grey[500], mr: 1, ml: 1, fontSize: 18 }}
          />
          <InputBase
            sx={{ flex: 1, fontSize: "0.875rem" }} // Smaller font
            placeholder="Search Case..."
            inputProps={{ "aria-label": "search case" }}
            value={searchTerm}
            onChange={handleSearch}
          />
        </Paper>

        {/* Filter Icon with Popup */}
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <IconButton aria-label="filter" onClick={handleFilterClick}>
              <Badge badgeContent={filterCount} color="primary">
                <FilterListIcon />
              </Badge>
            </IconButton>
            <Popper
              open={isFilterPopupOpen}
              anchorEl={anchorEl}
              placement="bottom-start"
              sx={{ zIndex: theme.zIndex.modal }} // TODO: Adjust zIndex if needed
            >
              <FilterPopup
                conclusions={uniqueConclusions}
                statuses={uniqueStatuses}
                severities={uniqueSeverities}
                applyFilters={applyFilters}
                currentFilters={advancedFilters} // Pass down the current applied filters
              />
            </Popper>
          </div>
        </ClickAwayListener>
      </Box>
    </Box>
  );
};

export default FiltersSection;
