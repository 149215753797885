import React from "react";
import {
  Box,
  Typography,
  Badge,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTheme } from "@mui/material/styles";
import { getRefreshButtonStyles } from "../../../utils/themeStyles";

interface TopBarProps {
  totalCases: number;
  onRefresh: () => void;
  loading: boolean;
}

const TopBar: React.FC<TopBarProps> = ({ totalCases, onRefresh, loading }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between", // Space between title and refresh button
        alignItems: "center",
        border: "none",
        marginBottom: theme.spacing(2), // Ensure consistent spacing
      }}
    >
      {/* Title and Badge */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="h3"
          fontWeight="bold"
          sx={{ marginRight: theme.spacing(1) }}
        >
          Cases
        </Typography>
        <Badge badgeContent={totalCases} color="primary" />
      </Box>

      {/* Refresh Button */}
      <Tooltip title={loading ? "Refreshing..." : "Refresh Cases"}>
        <span>
          {/* span wraps IconButton to handle Tooltip with disabled state */}
          <IconButton
            onClick={onRefresh}
            disabled={loading}
            sx={getRefreshButtonStyles(theme)}
          >
            {loading ? <CircularProgress size={24} /> : <RefreshIcon />}
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export default TopBar;
