import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface CaseTitleSectionProps {
  caseTitle: string;
  caseConclusion: string;
}

const CaseTitleSection: React.FC<CaseTitleSectionProps> = ({
  caseTitle,
  caseConclusion,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginRight: theme.spacing(2),
      }}
    >
      {/* <Typography
        variant="body2"
        sx={{
          color: theme.palette.info.main,
          marginTop: 0,
          marginBottom: "0.25rem",
        }}
      >
        Subject: {caseTitle}
      </Typography> */}
      <Typography
        variant="h3"
        sx={{
          fontWeight: "bold",
          color:
            caseConclusion === "Benign"
              ? theme.palette.success.main
              : theme.palette.error.main,
        }}
      >
        {caseConclusion}
      </Typography>
    </Box>
  );
};

export default CaseTitleSection;
