import React, { useEffect, useMemo, useRef, useState } from "react";
import { ui_global_settings, ui_shapes } from "./ui_settings";
import { Dashboard } from "./ui_classes";
import { useTheme } from "@mui/material/styles";
import HotspotPopup from "./HotspotPopup"; // The popup component for showing details
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import debounce from "lodash/debounce"; // Import debounce from lodash
import { Case } from "../../cases/types";
import { setCaseListAndIndex } from "../../case-navigation/redux/caseNavigationSlice";
import { useAppDispatch } from "../../../app/hooks";

interface EmbedCircleComponent2Props {
  data: any;
  filteredCases: Case[];
}

const EmbedCircleComponent2: React.FC<EmbedCircleComponent2Props> = ({
  data,
  filteredCases,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const dashboardRef = useRef<any>(null);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [clickedCaseId, setClickedCaseId] = useState<string | null>(null);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  // Redux: Get cases from the store
  const cases = useAppSelector((state) => state.cases.cases);

  // Memoize the clicked case to prevent unnecessary re-renders
  const clickedCase = useMemo(
    () => cases.find((c) => c.caseId === clickedCaseId) || null,
    [clickedCaseId, cases]
  );

  // Memoize globalSettings and shapes
  const globalSettings = useMemo(() => ui_global_settings(theme), [theme]);
  const shapes = useMemo(() => ui_shapes(theme), [theme]);

  const callbacks = useMemo(
    () => ({
      caseClick: (caseId: string) => {
        setClickedCaseId(caseId);
      },
      filter_click: (label: string) => {
        if (window.ReactDashboard?.onFilterClick) {
          window.ReactDashboard.onFilterClick(label);
        }
      },
      hotspot_filter_click: (label: string) => {
        if (window.ReactDashboard?.onHotspotFilterClick) {
          window.ReactDashboard.onHotspotFilterClick(label);
        }
      },
    }),
    []
  );

  // Initialize the Dashboard only once
  useEffect(() => {
    const reinitializeDashboard = () => {
      if (canvasRef.current) {
        const canvas = canvasRef.current;
        // Set up high-resolution canvas

        const ctx = canvas.getContext("2d");
        if (ctx) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        }

        setupHighResolutionCanvas(canvas);
        dashboardRef.current = new Dashboard(
          canvas,
          callbacks,
          globalSettings,
          shapes,
          0.8 * window.innerWidth
        );

        dashboardRef.current.update(JSON.stringify(data), true);
      }
    };
    if (canvasRef.current && !dashboardRef.current) {
      reinitializeDashboard();
      const canvas = canvasRef.current;

      dashboardRef.current = new Dashboard(
        canvas,
        callbacks,
        globalSettings,
        shapes,
        0.8 * window.innerWidth
      );

      dashboardRef.current.update(JSON.stringify(data), true);

      // const handleResize = () => {
      //   if (dashboardRef.current) {
      //     dashboardRef.current.resize(Math.floor(0.8 * window.innerWidth));
      //   }
      // };

      // window.addEventListener("resize", handleResize);

      // return () => {
      //   window.removeEventListener("resize", handleResize);
      //   dashboardRef.current = null; // Cleanup
      // };
    }
  }, [callbacks, globalSettings, shapes, data]);

  // Update data in the Dashboard when data changes
  useEffect(() => {
    if (dashboardRef.current) {
      dashboardRef.current.updateSettings(globalSettings, shapes);
      dashboardRef.current.update(JSON.stringify(data), true);
    }
  }, [theme, globalSettings, shapes, data]);

  // Handle window resize with debounce to prevent excessive calls
  useEffect(() => {
    const handleResize = debounce(() => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 0); // 100ms debounce delay

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      handleResize.cancel(); // Cancel any pending debounced calls
    };
  }, []);

  const closePopup = () => setClickedCaseId(null);

  const handleInvestigate = (caseId: string) => {
    // Find the index of this case in filteredCases
    const index = filteredCases.findIndex((c) => c.caseId === caseId);
    // Dispatch to Redux and navigate, for example:
    dispatch(
      setCaseListAndIndex({ source: "dashboard", cases: filteredCases, index })
    );
    navigate(`/cases/${caseId}`, { state: { from: "/dashboard" } });
  };

  // Helper to set up high-resolution canvas
  const setupHighResolutionCanvas = (canvas: HTMLCanvasElement) => {
    const ctx = canvas.getContext("2d");
    const dpr = window.devicePixelRatio || 1;

    // Set canvas dimensions accounting for pixel ratio
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;

    canvas.width = width * dpr;
    canvas.height = height * dpr;

    // Scale the context to match device pixel ratio
    if (ctx) {
      ctx.scale(dpr, dpr);
    }

    console.log(`Canvas setup: ${width}x${height} with DPR ${dpr}`);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "900px",
        height: "600px",
        backgroundColor: "transparent",
      }}
    >
      <canvas
        ref={canvasRef}
        width={900}
        height={600}
        style={{
          backgroundColor: "transparent",
          display: "block",
        }}
      />
      {clickedCase && (
        <HotspotPopup
          data={clickedCase}
          onClose={closePopup}
          onInvestigate={handleInvestigate}
        />
      )}
    </div>
  );
};

export default EmbedCircleComponent2;
