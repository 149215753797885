import React from "react";
import { Tooltip, Typography } from "@mui/material";
import { formatAlertDate } from "../../../utils/dateFormatter";

const AlertTimeDisplay = ({ timestamp }: { timestamp: string }) => {
  const formattedDate = formatAlertDate(timestamp);

  return (
    <Tooltip title={timestamp} placement="bottom-start">
      <Typography variant="body2" sx={{ cursor: "pointer" }}>
        {formattedDate}
      </Typography>
    </Tooltip>
  );
};

export default AlertTimeDisplay;
