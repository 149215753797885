import { combineReducers } from "@reduxjs/toolkit";
import casesReducer from "../features/cases/redux/casesSlice";
import authReducer from "../features/auth/redux/authSlice";
import caseDetailsReducer from "../features/case-details/redux/caseDetailsSlice";
import caseNavigationReducer from "../features/case-navigation/redux/caseNavigationSlice";

const rootReducer = combineReducers({
  cases: casesReducer, // Manages the case-related state
  auth: authReducer, // Manages the authentication state
  caseDetails: caseDetailsReducer, // Manages the case details state
  caseNavigation: caseNavigationReducer, // Manages case navigation on details page
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
