import React, { useEffect, useState } from "react";
import EmbedCircleComponent2 from "../features/dashboard/components/EmbedCircleComponent2"; // Import the component
import { useSelector } from "react-redux";
import { fetchCases } from "../api/casesApi";
import { RootState } from "../app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import dayjs from "dayjs";
import { Case } from "../features/cases/types"; // TODO: Awkward as this comes from cases
import { useTheme } from "@mui/material/styles";
import DashboardHeader from "../features/dashboard/components/DashboardHeader";
import { Box } from "@mui/material";
import DashboardHeaderSkeleton from "../features/dashboard/components/DashboardHeaderSkeleton";
import EmbedCircleSkeleton from "../features/dashboard/components/EmbedCircleSkeleton";

// Define the interface for dashboard data
interface DashboardData {
  CN_1: number;
  CN_2: number;
  Segments: {
    [key: string]: {
      points: any[];
      hotspots: any[];
    };
  };
  Radio_Buttons: {
    [key: string]: any;
  };
}

// Define the interface for your dashboard data
interface DashboardData {
  CN_1: number;
  CN_2: number;
  Segments: {
    [key: string]: {
      points: any[];
      hotspots: any[];
    };
  };
  Radio_Buttons: {
    [key: string]: any;
  };
}

// Read saved state once, at initialization
function getInitialFilters() {
  const savedState = JSON.parse(
    localStorage.getItem("DashboardPageState") || "{}"
  );
  return {
    timeFilter: savedState.timeFilter || "Last 24 Hrs",
    hotspotFilter: savedState.hotspotFilter || "Show All",
  };
}

const DashboardPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const cases = useAppSelector((state) => state.cases.cases);
  const loading = useAppSelector((state) => state.cases.loading);
  const { getAccessTokenSilently } = useAuth0();
  const [filteredData, setFilteredData] = useState<DashboardData | null>(null);

  // Use a lazy initializer so that this runs once per component mount,
  // ensuring the latest values from localStorage are used.
  const [
    { timeFilter: initialTimeFilter, hotspotFilter: initialHotspotFilter },
  ] = useState(getInitialFilters);

  // Load saved state from localStorage
  const [timeFilter, setTimeFilter] = useState<string>(initialTimeFilter);
  const [hotspotFilter, setHotspotFilter] =
    useState<string>(initialHotspotFilter);

  useEffect(() => {
    console.log("Setting DashboardPageState: ");
    localStorage.setItem(
      "DashboardPageState",
      JSON.stringify({
        timeFilter,
        hotspotFilter,
      })
    );
    console.log(
      "Set DashboardPageState: ",
      localStorage.getItem("DashboardPageState")
    );
  }, [timeFilter, hotspotFilter]);

  // theme and metrics
  const theme = useTheme();
  const [metrics, setMetrics] = useState({
    timeSaved: "0 hours",
    yearlyDollarsSaved: "$0",
    systemHealth: "16/16", // Placeholder
    evidenceCollected: 89388, // Hardcoded value
    denoisedAlerts: 0,
    unreviewedMaliciousAlerts: 0,
  });

  // Keep filteredCases in a state variable so we can pass them down
  const [filteredCases, setFilteredCases] = useState<Case[]>([]);

  useEffect(() => {
    // Fetch cases on mount if not present
    if (cases.length === 0) {
      const fetchData = async () => {
        try {
          const token = await getAccessTokenSilently();
          await dispatch(fetchCases(token)).unwrap();
        } catch (error: any) {
          console.error("Error fetching access token or cases:", error);
          // For testing purposes, you can use exampleEmails
          // dispatch({
          //   type: "cases/fetchCases/fulfilled",
          //   payload: exampleEmails,
          // });
        }
      };
      fetchData();
    }
  }, [dispatch, getAccessTokenSilently, cases.length]);

  useEffect(() => {
    if (cases.length) {
      // Calculate metrics based on cases

      // Time saved: Each case takes 18 mins, rounded to the nearest hour
      const timeSavedInMinutes = cases.length * 18;
      const timeSavedInHours = Math.round(timeSavedInMinutes / 60);
      const timeSaved = `${timeSavedInHours} hours`;

      // Yearly Dollars Saved: (time_saved * 12 * (125000 / 2080))
      const yearlyDollarsSaved = `$${(
        timeSavedInHours *
        12 *
        (125000 / 2080)
      ).toLocaleString(undefined, { maximumFractionDigits: 0 })}`;

      // Denoised %: Benign Alerts / (Malicious + Benign)
      const benignCount = cases.filter(
        (caseItem) => caseItem.conclusion === "Benign"
      ).length;
      const maliciousCount = cases.filter(
        (caseItem) => caseItem.conclusion === "Malicious"
      ).length;

      const denoisedAlerts = Math.ceil(
        (benignCount / (benignCount + maliciousCount)) * 100
      );

      // Unreviewed Malicious Alerts: Malicious alerts without feedback
      const unreviewedMaliciousAlerts = cases.filter(
        (caseItem) =>
          caseItem.conclusion === "Malicious" && caseItem.feedback == null
      ).length;

      // Total Alerts (formerly Evidence Collected): Total number of cases
      const evidenceCollected = cases.length;

      // Update metrics state
      setMetrics({
        timeSaved,
        yearlyDollarsSaved,
        systemHealth: "16/16", // Placeholder
        evidenceCollected,
        denoisedAlerts,
        unreviewedMaliciousAlerts,
      });

      // Filter cases by time and hotspot
      const filteredByTime = filterCasesByTime(cases, timeFilter);
      const finalFilteredCases = filteredByTime.filter((item) => {
        if (hotspotFilter === "Reviewed") return item.feedback !== null;
        if (hotspotFilter === "Unreviewed") return item.feedback === null;
        return true;
      });

      // Store filteredCases in state so we can pass them down
      setFilteredCases(finalFilteredCases);

      const escalated = finalFilteredCases.filter(
        (item) => item.conclusion === "Malicious"
      ).length;
      const denoised = finalFilteredCases.filter(
        (item) => item.conclusion === "Benign"
      ).length;

      // Generate hotspots
      const hotspots = finalFilteredCases.map((item) => {
        let size: "small" | "medium" | "large" = "medium";
        if (item.severity === "Low") size = "small";
        else if (item.severity === "Medium") size = "medium";
        else if (item.severity === "High" || item.severity === "Critical")
          size = "large";

        return {
          shape_name: item.feedback ? "Reviewed" : "Unreviewed",
          callback_fnc: "caseClick",
          params: [item.caseId],
          size,
          createdAt: item.createdAt,
        };
      });

      // Group hotspots by segment
      const segmentsData: { [key: string]: Case[] } = {};
      finalFilteredCases.forEach((item) => {
        const segmentName = item.attackSurface || "Unknown";
        if (!segmentsData[segmentName]) {
          segmentsData[segmentName] = [];
        }
        segmentsData[segmentName].push(item);
      });

      // Initialize segments with default empty data
      const initialSegments = {
        Email: {
          points: [],
          hotspots: [],
        },
        Endpoint: {
          points: [],
          hotspots: [],
        },
        Cloud: {
          points: [],
          hotspots: [],
        },
      };

      // Generate segments with hotspots and points
      const segments: any = { ...initialSegments };
      Object.keys(segmentsData).forEach((segmentName) => {
        const segmentCases = segmentsData[segmentName];

        // Generate hotspots for the segment with dynamic size
        const hotspots = segmentCases.map((item) => {
          let size = "medium"; // Default size
          if (item.severity === "Low") size = "small";
          else if (item.severity === "Medium") size = "medium";
          else if (item.severity === "High" || item.severity === "Critical")
            size = "large";

          return {
            shape_name: item.feedback ? "Reviewed" : "Unreviewed",
            callback_fnc: "caseClick",
            params: [item.caseId],
            size: size, // Set size dynamically based on severity
            createdAt: item.createdAt,
          };
        });

        // Update segmentCases to include size for hotspots
        const updatedSegmentCases = segmentCases.map((item) => ({
          ...item,
          size: hotspots.find((hotspot) => hotspot.params[0] === item.caseId)
            ?.size,
        }));

        // Generate time series data
        const dateCounts = generateTimeSeriesData(segmentCases, segmentName);
        // Add timestamps to graph points for alignment
        const graphPoints = generateGraphPoints(dateCounts);

        // Include in segments object
        segments[segmentName] = {
          points: graphPoints.map((p) => [p.x, p.y]), // Convert to array format
          hotspots: hotspots,
          cases: updatedSegmentCases, // Update cases with size
          graphPoints, // Attach graph points to the segment
        };
      });

      // Update filteredData state
      setFilteredData({
        CN_1: escalated,
        CN_2: denoised,
        Segments: segments,
        Radio_Buttons: {
          time_filters: {
            center_angle: 30,
            angle_length: 40,
            angle_margin: 5,
            label_size: 0.55,
            label_offset: 0.35,
            callback_fnc: "filter_click",
            selected_id: getTimeFilterId(timeFilter),
            selected_label_color: "#5e40f6",
            buttons: [
              {
                selected_shape_name: "Selected Radio",
                unselected_shape_name: "Unselected Radio",
                label: "Last 24 Hrs",
              },
              {
                selected_shape_name: "Selected Radio",
                unselected_shape_name: "Unselected Radio",
                label: "Last Week",
              },
              {
                selected_shape_name: "Selected Radio",
                unselected_shape_name: "Unselected Radio",
                label: "Last Month",
              },
              {
                selected_shape_name: "Selected Radio",
                unselected_shape_name: "Unselected Radio",
                label: "All",
              },
            ],
          },
          hotspot_filters: {
            center_angle: 330,
            angle_length: 22,
            angle_margin: 2,
            label_size: 0.65,
            label_offset: 0.25,
            label_y_offset: -0.25,
            callback_fnc: "hotspot_filter_click",
            selected_id: getHotspotFilterId(hotspotFilter),
            buttons: [
              {
                selected_shape_name: "Reviewed Selected",
                unselected_shape_name: "Reviewed",
                label: "Reviewed",
              },
              {
                selected_shape_name: "Unreviewed Selected",
                unselected_shape_name: "Unreviewed",
                label: "Unreviewed",
              },
              {
                selected_shape_name: "Show All Selected",
                unselected_shape_name: "Show All",
                label: "Show All",
              },
            ],
          },
        },
      });
    }
  }, [cases, timeFilter, hotspotFilter]);

  // Helper function to filter cases by time
  const filterCasesByTime = (cases: Case[], timeFilter: string) => {
    if (timeFilter === "All") {
      return cases; // Return all cases without time filtering
    }
    const now = new Date();
    let days = 1;
    if (timeFilter === "Last Week") days = 7;
    else if (timeFilter === "Last Month") days = 30;
    return cases.filter((item) => {
      const caseDate = new Date(item.createdAt);
      const diffInDays =
        (now.getTime() - caseDate.getTime()) / (1000 * 60 * 60 * 24);
      return diffInDays <= days;
    });
  };

  // Helper functions to get selected_id for filters
  const getTimeFilterId = (label: string) => {
    switch (label) {
      case "Last 24 Hrs":
        return 0;
      case "Last Week":
        return 1;
      case "Last Month":
        return 2;
      case "All":
        return 3;
      default:
        return 0;
    }
  };

  const getHotspotFilterId = (label: string) => {
    switch (label) {
      case "Reviewed":
        return 0;
      case "Unreviewed":
        return 1;
      case "Show All":
        return 2;
      default:
        return 2;
    }
  };

  // Helper function to generate time series data for a segment
  const generateTimeSeriesData = (cases: Case[], segmentName: string) => {
    // Filter cases for the given segment
    const segmentCases = cases.filter(
      (item) => item.attackSurface === segmentName
    );

    // Create a map to hold counts per day
    const dateCounts: { [date: string]: number } = {};

    segmentCases.forEach((item) => {
      const date = new Date(item.createdAt);
      const dateString = date.toISOString().split("T")[0]; // Format as YYYY-MM-DD

      if (dateCounts[dateString]) {
        dateCounts[dateString]++;
      } else {
        dateCounts[dateString] = 1;
      }
    });

    return dateCounts;
  };

  const generateGraphPoints = (dateCounts: { [date: string]: number }) => {
    // Get all dates as an array and sort them
    const dates = Object.keys(dateCounts).sort();

    // Map dates to x-values (e.g., days since the earliest date)
    const startDate = new Date(dates[0]);
    const points = dates.map((date) => {
      const currentDate = new Date(date);
      const x = Math.floor(
        (currentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
      ); // Difference in days
      const y = dateCounts[date]; // Value corresponding to this date

      return { x, y, date }; // Ensure date is passed here
    });

    return points;
  };

  // Handle filter clicks
  useEffect(() => {
    window.ReactDashboard = {
      onFilterClick: (filterLabel: string) => {
        setTimeFilter(filterLabel);
      },
      onHotspotFilterClick: (filterLabel: string) => {
        setHotspotFilter(filterLabel);
      },
      onCaseClick: (x: number, y: number, caseId: string) => {
        console.log(`Hotspot clicked at (${x}, ${y}) with caseId: ${caseId}`);
        // Implement navigation to /cases/<caseId>
        // window.location.href = `/cases/${caseId}`;
      },
    };

    return () => {
      delete window.ReactDashboard;
    };
  }, []);

  // Ensure that the dashboard updates when the theme changes
  useEffect(() => {
    if (filteredData) {
      // Trigger a re-render
      setFilteredData({ ...filteredData });
    }
  }, [theme]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
        height: "100vh",
        boxSizing: "border-box",
      }}
    >
      {loading ? (
        <DashboardHeaderSkeleton />
      ) : (
        <DashboardHeader metrics={metrics} />
      )}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: theme.spacing(2), // Optional padding
          boxSizing: "border-box",
        }}
      >
        {filteredData ? (
          <EmbedCircleComponent2
            key={JSON.stringify(filteredData)}
            data={filteredData}
            filteredCases={filteredCases} // Pass the filtered cases here
          />
        ) : (
          <EmbedCircleSkeleton />
        )}
      </Box>
    </Box>
  );
};

export default DashboardPage;
