import React, { ReactNode } from "react";
import { Box } from "@mui/material";

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh", // Full viewport height
        overflow: "hidden", // Prevent overflow
      }}
    >
      {/* Page Content */}
      <Box
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflowY: "auto",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default MainLayout;
