import React from "react";
import { EdgeProps, getBezierPath, Position } from "@xyflow/react";
import { useTheme } from "@mui/material/styles";

interface EdgeData {
  sourceIsTrigger?: boolean;
  targetIsTrigger?: boolean;
}

const CustomEdge: React.FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  style = {},
  data,
}) => {
  const theme = useTheme();

  // Generate the edge path
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition: Position.Right,
    targetX,
    targetY,
    targetPosition: Position.Left,
  });

  // Determine if the edge is a trigger edge
  const isTriggerEdge =
    (data as EdgeData)?.sourceIsTrigger || (data as EdgeData)?.targetIsTrigger;

  // Define dynamic styles based on the theme
  const edgeStyle: React.CSSProperties = {
    ...style,
    stroke: isTriggerEdge
      ? theme.palette.error.main
      : theme.palette.text.primary,
    strokeWidth: isTriggerEdge ? 2 : 1, // Thicker stroke for trigger edges
    opacity: isTriggerEdge ? 0.8 : 1,
    animation: isTriggerEdge ? `blink 1s linear infinite` : "none",
    fill: "none",
    strokeLinecap: "round", // Optional: smoother line caps
    strokeLinejoin: "round", // Optional: smoother line joins
  };

  // Define keyframes for blink animation
  const blinkKeyframes = `
    @keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.4;
      }
      100% {
        opacity: 1;
      }
    }
  `;

  // Define arrowhead color
  const arrowFill = isTriggerEdge
    ? theme.palette.error.main
    : theme.palette.text.primary;

  return (
    <>
      {/* Inject keyframes for blink animation */}
      <style>{blinkKeyframes}</style>

      <defs>
        <marker
          id={`arrowhead-${id}`}
          markerWidth="10"
          markerHeight="7"
          refX="10"
          refY="3.5"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <polygon points="0 0, 10 3.5, 0 7" fill={arrowFill} />
        </marker>
      </defs>
      <path
        id={id}
        style={edgeStyle}
        d={edgePath}
        markerEnd={`url(#arrowhead-${id})`}
      />
    </>
  );
};

export default CustomEdge;
