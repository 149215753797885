import { createAsyncThunk } from "@reduxjs/toolkit";
import { Case } from "../features/cases/types";

export const fetchCases = createAsyncThunk(
  "cases/fetchCases",
  async (accessToken: string, { rejectWithValue }) => {
    if (!accessToken) {
      return rejectWithValue("User is not authenticated");
    }

    const apiUrl = process.env.REACT_APP_CASES_API_URL as string;
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        // Parse error response
        const errorData = await response.json();
        return rejectWithValue(errorData.message || "Failed to fetch cases");
      }

      const data: Case[] = await response.json();
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message || "Failed to fetch cases");
    }
  }
);
