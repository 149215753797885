import React, { useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  List,
  ListItem,
  Badge,
  Paper,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface URLData {
  benign: string[];
  malicious: string[];
  inconclusive: string[];
}

interface AttachmentData {
  benign: Attachment[];
  malicious: Attachment[];
  inconclusive: Attachment[];
}

interface Attachment {
  filename: string;
  mimetype: string;
  hash: string;
}

interface Analysis {
  name: string;
  kind: string;
  conclusion: string | null;
  summary: string;
  data?: URLData | AttachmentData;
}

interface InvestigativeStepsTabsProps {
  analyses: Analysis[];
}

const InvestigativeStepsTabs: React.FC<InvestigativeStepsTabsProps> = ({
  analyses = [],
}) => {
  const theme = useTheme();

  // Determine the index of the first malicious tab
  const maliciousTabIndex = analyses.findIndex(
    (analysis) => analysis.conclusion === "Malicious"
  );

  // Set the default tab index to the first malicious tab or default to 0
  const [activeTab, setActiveTab] = useState(
    maliciousTabIndex !== -1 ? maliciousTabIndex : 0
  );

  const defaultTabs = ["URLs", "Attachments", "Call To Action", "Headers"];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const tabsToRender =
    analyses.length > 0 ? analyses.map((a) => a.name) : defaultTabs;

  return (
    <Paper
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        borderRadius: "8px",
        overflow: "hidden", // Ensures any overflowing content is clipped
        boxShadow: theme.shadows[1],
      }}
    >
      {/* Tabs Section on Paper */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="fullWidth"
        scrollButtons="auto"
        sx={{
          borderBottom: `1px solid ${theme.palette.grey[300]}`, // Slight horizontal line under the tabs
          "& .MuiTab-root": {
            fontSize: "0.875rem",
            minHeight: "2.5rem",
            padding: "0.5rem",
            textTransform: "none",
            color: theme.palette.grey[600], // Non-selected tabs text color
            "&.Mui-selected": {
              color: theme.palette.text.primary,
            },
          },
          "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.info.main, // Underline color for the selected tab
          },
        }}
      >
        {tabsToRender.map((tabName, index) => (
          <Tab
            key={index}
            label={
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                {tabName}
                {analyses.length > 0 &&
                  analyses[index].conclusion !== "Benign" && (
                    <Badge
                      color="error"
                      variant="dot"
                      sx={{
                        position: "absolute",
                        top: "-4px",
                        right: "-4px",
                      }}
                    />
                  )}
              </Box>
            }
          />
        ))}
      </Tabs>

      {/* Rendered Content Section on the Same Paper */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          overflowX: "hidden", // Hide horizontal overflow
          wordBreak: "break-word", // Break long words/URLs to new lines
          padding: "1rem",
        }}
      >
        {tabsToRender.map((tabName, index) => (
          <Box
            key={index}
            sx={{
              display: activeTab === index ? "block" : "none",
            }}
          >
            {analyses.length > 0 ? (
              <>
                {/* Conclusion */}
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    whiteSpace: "normal",
                    fontWeight: "bold",
                    color:
                      analyses[index].conclusion === "Benign"
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                  }}
                >
                  {analyses[index].conclusion || "No Conclusion Available"}
                </Typography>

                {/* Summary */}
                {analyses[index].summary && (
                  <>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, fontWeight: "bold" }}
                    >
                      Summary:
                    </Typography>
                    <List sx={{ paddingLeft: 2 }}>
                      {analyses[index].summary
                        ?.split("•")
                        .map((line) => line.trim())
                        .filter((line) => line)
                        .map((line, i) => (
                          <ListItem
                            key={i}
                            sx={{ display: "list-item", paddingLeft: 0 }}
                          >
                            <Typography variant="body2">• {line}</Typography>
                          </ListItem>
                        ))}
                    </List>
                  </>
                )}

                {/* Render Specific Content Based on the Tab Name (URLs, Attachments, etc.) */}
                {analyses[index].name === "URLs" && (
                  <>
                    {/* Benign URLs Section */}
                    {(() => {
                      const data = analyses[index].data as URLData;
                      const benignUrls = data?.benign ?? [];
                      return benignUrls.length > 0 ? (
                        <Box sx={{ mb: 1 }}>
                          <Typography
                            variant="body2"
                            sx={{
                              mt: 1,
                              fontWeight: "bold",
                            }}
                          >
                            Benign URLs:
                          </Typography>
                          <List>
                            {benignUrls.map((url, i) => (
                              <ListItem key={i} sx={{ paddingLeft: 0 }}>
                                <Typography
                                  variant="body2"
                                  sx={{ color: theme.palette.success.main }}
                                >
                                  {url}
                                </Typography>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      ) : null;
                    })()}

                    {/* Malicious URLs Section */}
                    {(() => {
                      const data = analyses[index].data as URLData;
                      const maliciousUrls = data?.malicious ?? [];
                      return maliciousUrls.length > 0 ? (
                        <Box sx={{ mb: 1 }}>
                          <Typography
                            variant="body2"
                            sx={{
                              mt: 1,
                              fontWeight: "bold",
                            }}
                          >
                            Malicious URLs:
                          </Typography>
                          <List>
                            {maliciousUrls.map((url, i) => (
                              <ListItem key={i} sx={{ paddingLeft: 0 }}>
                                <Typography
                                  variant="body2"
                                  sx={{ color: theme.palette.error.main }}
                                >
                                  {url}
                                </Typography>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      ) : null;
                    })()}

                    {/* Inconclusive URLs Section */}
                    {(() => {
                      const data = analyses[index].data as URLData;
                      const inconclusiveUrls = data?.inconclusive ?? [];
                      return inconclusiveUrls.length > 0 ? (
                        <Box sx={{ mb: 1 }}>
                          <Typography
                            variant="body2"
                            sx={{
                              mt: 1,
                              fontWeight: "bold",
                            }}
                          >
                            Inconclusive URLs:
                          </Typography>
                          <List>
                            {inconclusiveUrls.map((url, i) => (
                              <ListItem key={i} sx={{ paddingLeft: 0 }}>
                                <Typography
                                  variant="body2"
                                  sx={{ color: theme.palette.text.primary }}
                                >
                                  {url}
                                </Typography>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      ) : null;
                    })()}
                  </>
                )}

                {analyses[index].name === "Attachments" && (
                  <>
                    {/* Benign Attachments Section */}
                    {(() => {
                      const data = analyses[index].data as AttachmentData;
                      const benignAttachments = data?.benign ?? [];
                      return benignAttachments.length > 0 ? (
                        <Box sx={{ mb: 1 }}>
                          <Typography
                            variant="body2"
                            sx={{
                              mt: 1,
                              fontWeight: "bold",
                            }}
                          >
                            Benign Attachments:
                          </Typography>
                          <List>
                            {benignAttachments.map((attachment, i) => (
                              <ListItem key={i}>
                                <Box
                                  sx={{
                                    padding: "0.75rem",
                                    border: "1px solid",
                                    borderColor: "grey.300",
                                    borderRadius: "8px",
                                    mb: 1, // spacing between cards
                                    boxShadow: 1,
                                    backgroundColor:
                                      theme.palette.background.paper,
                                    color: theme.palette.success.main,
                                    wordBreak: "break-word", // Ensures long text breaks
                                  }}
                                >
                                  <Typography variant="body2" fontWeight="bold">
                                    Filename:
                                  </Typography>
                                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                                    {attachment.filename}
                                  </Typography>

                                  <Typography variant="body2" fontWeight="bold">
                                    MIME Type:
                                  </Typography>
                                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                                    {attachment.mimetype}
                                  </Typography>

                                  <Typography variant="body2" fontWeight="bold">
                                    Hash:
                                  </Typography>
                                  <Typography variant="body2">
                                    {attachment.hash}
                                  </Typography>
                                </Box>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      ) : null;
                    })()}

                    {/* Malicious Attachments Section */}
                    {(() => {
                      const data = analyses[index].data as AttachmentData;
                      const maliciousAttachments = data?.malicious ?? [];
                      return maliciousAttachments.length > 0 ? (
                        <Box sx={{ mb: 1 }}>
                          <Typography
                            variant="body2"
                            sx={{
                              mt: 1,
                              fontWeight: "bold",
                            }}
                          >
                            Malicious Attachments:
                          </Typography>
                          <List>
                            {maliciousAttachments.map((attachment, i) => (
                              <ListItem key={i}>
                                <Box
                                  sx={{
                                    padding: "0.75rem",
                                    border: "1px solid",
                                    borderColor: "grey.300",
                                    borderRadius: "8px",
                                    mb: 1, // spacing between cards
                                    boxShadow: 1,
                                    backgroundColor:
                                      theme.palette.background.paper,
                                    color: theme.palette.error.main,
                                    wordBreak: "break-word", // Ensures long text breaks
                                  }}
                                >
                                  <Typography variant="body2" fontWeight="bold">
                                    Filename:
                                  </Typography>
                                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                                    {attachment.filename}
                                  </Typography>

                                  <Typography variant="body2" fontWeight="bold">
                                    MIME Type:
                                  </Typography>
                                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                                    {attachment.mimetype}
                                  </Typography>

                                  <Typography variant="body2" fontWeight="bold">
                                    Hash:
                                  </Typography>
                                  <Typography variant="body2">
                                    {attachment.hash}
                                  </Typography>
                                </Box>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      ) : null;
                    })()}

                    {/* Inconclusive Attachments Section */}
                    {(() => {
                      const data = analyses[index].data as AttachmentData;
                      const inconclusiveAttachments = data?.inconclusive ?? [];
                      return inconclusiveAttachments.length > 0 ? (
                        <Box sx={{ mb: 1 }}>
                          <Typography
                            variant="body2"
                            sx={{
                              mt: 1,
                              fontWeight: "bold",
                            }}
                          >
                            Inconclusive Attachments:
                          </Typography>
                          <List>
                            {inconclusiveAttachments.map((attachment, i) => (
                              <ListItem key={i}>
                                <Box
                                  sx={{
                                    padding: "0.75rem",
                                    border: "1px solid",
                                    borderColor: "grey.300",
                                    borderRadius: "8px",
                                    mb: 1, // spacing between cards
                                    boxShadow: 1,
                                    backgroundColor:
                                      theme.palette.background.paper,
                                    color: theme.palette.info.main,
                                    wordBreak: "break-word", // Ensures long text breaks
                                  }}
                                >
                                  <Typography variant="body2" fontWeight="bold">
                                    Filename:
                                  </Typography>
                                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                                    {attachment.filename}
                                  </Typography>

                                  <Typography variant="body2" fontWeight="bold">
                                    MIME Type:
                                  </Typography>
                                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                                    {attachment.mimetype}
                                  </Typography>

                                  <Typography variant="body2" fontWeight="bold">
                                    Hash:
                                  </Typography>
                                  <Typography variant="body2">
                                    {attachment.hash}
                                  </Typography>
                                </Box>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      ) : null;
                    })()}
                  </>
                )}
              </>
            ) : (
              <Typography
                variant="body2"
                sx={{ mt: 1, mb: 1, fontWeight: "bold" }}
              >
                No Data
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default InvestigativeStepsTabs;
