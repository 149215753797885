import React, {
  createContext,
  useMemo,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { ThemeProvider, Theme } from "@mui/material/styles";
import {
  lightTheme,
  darkTheme,
  sepiaTheme,
  highContrastTheme,
} from "../config/theme";
import CssBaseline from "@mui/material/CssBaseline";

type ThemeMode = "light" | "dark" | "sepia" | "high-contrast";

interface ThemeContextProps {
  currentTheme: ThemeMode;
  setTheme: (theme: ThemeMode) => void;
}

export const CustomThemeContext = createContext<ThemeContextProps>({
  currentTheme: "light",
  setTheme: () => {},
});

interface Props {
  children: ReactNode;
}

export const CustomThemeProvider: React.FC<Props> = ({ children }) => {
  // Retrieve saved theme from localStorage or default to 'light'
  const getInitialTheme = (): ThemeMode => {
    const savedTheme = localStorage.getItem("appTheme") as ThemeMode | null;
    if (savedTheme) return savedTheme;
    // Optionally, detect system preference
    const prefersDark =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;
    return prefersDark ? "dark" : "light";
  };

  const [currentTheme, setCurrentTheme] = useState<ThemeMode>(getInitialTheme);

  // Save theme to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("appTheme", currentTheme);
  }, [currentTheme]);

  const setTheme = (theme: ThemeMode) => {
    setCurrentTheme(theme);
  };

  const theme: Theme = useMemo(() => {
    switch (currentTheme) {
      case "dark":
        return darkTheme;
      case "sepia":
        return sepiaTheme;
      case "high-contrast":
        return highContrastTheme;
      case "light":
      default:
        return lightTheme;
    }
  }, [currentTheme]);

  return (
    <CustomThemeContext.Provider value={{ currentTheme, setTheme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CustomThemeContext.Provider>
  );
};
