import React, { useContext } from "react";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { Brightness4, Brightness7, Palette } from "@mui/icons-material";
import { CustomThemeContext } from "../../context/ThemeContext";

const ThemeToggle: React.FC = () => {
  const { currentTheme, setTheme } = useContext(CustomThemeContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleThemeChange = (theme: string) => {
    setTheme(theme as any);
    handleClose();
  };

  const getIcon = () => {
    switch (currentTheme) {
      case "dark":
        return <Brightness7 />;
      case "light":
        return <Brightness4 />;
      case "sepia":
        return <Palette />;
      case "high-contrast":
        return <Palette />;
      default:
        return <Brightness4 />;
    }
  };

  return (
    <>
      <Tooltip title="Change Theme">
        <IconButton onClick={handleClick} color="inherit">
          {getIcon()}
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          selected={currentTheme === "light"}
          onClick={() => handleThemeChange("light")}
        >
          Light Mode
        </MenuItem>
        <MenuItem
          selected={currentTheme === "dark"}
          onClick={() => handleThemeChange("dark")}
        >
          Dark Mode
        </MenuItem>
        <MenuItem
          selected={currentTheme === "sepia"}
          onClick={() => handleThemeChange("sepia")}
        >
          Sepia Mode
        </MenuItem>
        {/* <MenuItem
          selected={currentTheme === "high-contrast"}
          onClick={() => handleThemeChange("high-contrast")}
        >
          High Contrast Mode
        </MenuItem> */}
      </Menu>
    </>
  );
};

export default ThemeToggle;
