import React, { useState, useEffect, useCallback } from "react";
import { Box, useTheme, Snackbar, Alert } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../src/app/rootReducer";
import { Case } from "../features/cases/types";
import { useAppDispatch } from "../app/hooks";
import { fetchCases } from "../../src/api/casesApi";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSkeleton from "../features/cases/components/LoadingSkeleton";
import ErrorDisplay from "../features/cases/components/ErrorDisplay";
import TopBar from "../features/cases/components/TopBar";
import FiltersSection from "../features/cases/components/FiltersSection";
import CasesTable from "../features/cases/components/CasesTable";
import PaginationControls from "../features/cases/components/PaginationControls";
import dayjs from "dayjs";

function getInitialCasesListState() {
  const savedState = JSON.parse(
    localStorage.getItem("CasesListPageState") || "{}"
  );
  return {
    activeFilter: savedState.activeFilter || "Escalated",
    searchTerm: savedState.searchTerm || "",
    advancedFilters: savedState.advancedFilters || null,
    page: savedState.page || 1,
    order: savedState.order || undefined,
    orderBy: savedState.orderBy || undefined,
  };
}

const CasesListPage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  // Redux store data
  const cases = useSelector((state: RootState) => state.cases.cases);
  const loading = useSelector((state: RootState) => state.cases.loading);
  const error = useSelector((state: RootState) => state.cases.error);

  // Load initial state from localStorage
  const {
    activeFilter: initialActiveFilter,
    searchTerm: initialSearchTerm,
    advancedFilters: initialAdvancedFilters,
    page: initialPage,
    order: initialOrder,
    orderBy: initialOrderBy,
  } = getInitialCasesListState();

  // Local state
  const [activeFilter, setActiveFilter] = useState(initialActiveFilter);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [advancedFilters, setAdvancedFilters] = useState<any>(
    initialAdvancedFilters
  );
  const [page, setPage] = useState(initialPage);
  const rowsPerPage = 15;
  const [order, setOrder] = useState<"asc" | "desc" | undefined>(initialOrder);
  const [orderBy, setOrderBy] = useState<keyof Case | undefined>(
    initialOrderBy
  );
  const [filteredCases, setFilteredCases] = useState<Case[]>([]);

  // Snackbar state variables
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  // Stabilize setPage using useCallback
  const handleSetPage = useCallback((newPage: React.SetStateAction<number>) => {
    setPage(newPage);
  }, []);

  // Persist state changes to localStorage
  useEffect(() => {
    const stateToSave = {
      activeFilter,
      searchTerm,
      advancedFilters,
      page,
      order,
      orderBy,
    };
    localStorage.setItem("CasesListPageState", JSON.stringify(stateToSave));
  }, [activeFilter, searchTerm, advancedFilters, page, order, orderBy]);

  // Fetch cases on mount if not present
  useEffect(() => {
    if (cases.length === 0) {
      const fetchData = async () => {
        try {
          const token = await getAccessTokenSilently();
          // Assuming fetchCases is an async thunk and returns a promise
          await dispatch(fetchCases(token)).unwrap();
          // If successful, show success snackbar
          setSnackbarMessage("Cases loaded successfully!");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
        } catch (error: any) {
          console.error("Error fetching access token or cases:", error);
          // If there's an error, show error snackbar
          setSnackbarMessage("Failed to load cases.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      };
      fetchData();
    }
  }, [dispatch, getAccessTokenSilently, cases.length]);

  // This function applies activeFilter, searchTerm, and advanced filters to filter the cases
  const filterCases = useCallback(() => {
    let filteredData = [...cases];

    // Apply advanced filters first if they exist
    if (advancedFilters) {
      filteredData = applyAdvancedFilters(filteredData, advancedFilters);
    }

    // Apply activeFilter logic if advancedFilters is not applied
    if (!advancedFilters) {
      if (activeFilter === "Escalated") {
        filteredData = filteredData.filter(
          (caseItem) =>
            caseItem.status !== "Auto Closed" &&
            caseItem.conclusion !== "Benign"
        );
      } else if (activeFilter === "Benign") {
        filteredData = filteredData.filter(
          (caseItem) =>
            caseItem.status === "Auto Closed" ||
            caseItem.conclusion === "Benign"
        );
      }
      // If "all", we do not filter by conclusion or status
    }

    // Apply searchTerm logic
    if (searchTerm.trim() !== "") {
      const term = searchTerm.toLowerCase();
      filteredData = filteredData.filter((caseItem) => {
        return Object.values(caseItem).some((val) =>
          val?.toString().toLowerCase().includes(term)
        );
      });
    }

    setFilteredCases(filteredData);
    setPage(1); // Reset page whenever the filters are applied
  }, [cases, activeFilter, searchTerm, advancedFilters]);

  // Update filtered cases whenever cases, activeFilter, searchTerm, or advancedFilters change
  useEffect(() => {
    filterCases();
  }, [filterCases]);

  // Extract unique filter values from cases data
  const uniqueConclusions = Array.from(
    new Set(cases.map((c) => c.conclusion).filter(Boolean))
  );
  const uniqueStatuses = Array.from(
    new Set(cases.map((c) => c.status).filter(Boolean))
  );
  const uniqueSeverities = Array.from(
    new Set(cases.map((c) => c.severity).filter(Boolean))
  );

  // Apply advanced filters and update state
  const handleApplyFilters = (filters: any) => {
    setSearchTerm(""); // Clear search term
    setActiveFilter("all"); // Switch to "all" filter
    setAdvancedFilters(filters); // Set the advanced filters so they are applied in filterCases
    setPage(1); // Reset page whenever the filters are applied
  };

  // Function to apply advanced filters to the cases array
  const applyAdvancedFilters = (
    casesToFilter: Case[],
    filters: any
  ): Case[] => {
    const {
      conclusions,
      statuses,
      severities,
      createdAt,
      alertTimestamp,
      identities,
    } = filters;

    let filtered = [...casesToFilter];

    // Filter by conclusion
    if (conclusions.length > 0) {
      filtered = filtered.filter((caseItem) => {
        return conclusions.includes(caseItem.conclusion);
      });
    }

    // Filter by status
    if (statuses.length > 0) {
      filtered = filtered.filter((caseItem) => {
        return statuses.includes(caseItem.status);
      });
    }

    // Filter by severity
    if (severities.length > 0) {
      filtered = filtered.filter((caseItem) => {
        return severities.includes(caseItem.severity);
      });
    }

    // Created At Filter
    if (createdAt[0] || createdAt[1]) {
      filtered = filtered.filter((caseItem) => {
        const caseDate = dayjs(caseItem.createdAt);
        const [start, end] = createdAt;
        if (start && caseDate.isBefore(dayjs(start), "day")) {
          return false;
        }
        if (end && caseDate.isAfter(dayjs(end), "day")) {
          return false;
        }
        return true;
      });
    }

    // Alert Timestamp Filter
    if (alertTimestamp[0] || alertTimestamp[1]) {
      filtered = filtered.filter((caseItem) => {
        if (!caseItem.alertTimestamp) {
          return false;
        }
        const alertDate = dayjs(caseItem.alertTimestamp);
        const [alertStart, alertEnd] = alertTimestamp;
        if (alertStart && alertDate.isBefore(dayjs(alertStart), "day")) {
          return false;
        }
        if (alertEnd && alertDate.isAfter(dayjs(alertEnd), "day")) {
          return false;
        }
        return true;
      });
    }

    // Identity Filter
    if (identities.length > 0) {
      filtered = filtered.filter((caseItem) => {
        const caseIdentity = caseItem.identity
          ? caseItem.identity.toLowerCase()
          : "";
        return identities.some((identity: string) =>
          caseIdentity.includes(identity.toLowerCase())
        );
      });
    }

    return filtered;
  };

  // Handle refresh with Snackbar feedback
  const handleRefresh = async () => {
    try {
      const token = await getAccessTokenSilently();
      // Assuming fetchCases is an async thunk and returns a promise
      await dispatch(fetchCases(token)).unwrap();
      // If successful, show success snackbar
      setSnackbarMessage("Cases refreshed successfully!");
      setSnackbarSeverity("success");
    } catch (error: any) {
      console.error("Error refreshing cases:", error);
      // If there's an error, show error snackbar
      setSnackbarMessage("Failed to refresh cases.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  // Handle Snackbar close
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // Handle loading and error states
  if (loading && cases.length === 0) return <LoadingSkeleton />;
  if (error) return <ErrorDisplay error={error} />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        width: "100%",
      }}
    >
      {/* Top Bar with Refresh Button */}
      <TopBar
        totalCases={cases.length}
        onRefresh={handleRefresh}
        loading={loading}
      />

      {/* Filters and Search */}
      <FiltersSection
        cases={cases}
        setFilteredCases={setFilteredCases}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        uniqueConclusions={uniqueConclusions}
        uniqueStatuses={uniqueStatuses}
        uniqueSeverities={uniqueSeverities}
        applyFilters={handleApplyFilters}
        setPage={handleSetPage}
        advancedFilters={advancedFilters}
      />

      {/* Cases Table */}
      <CasesTable
        cases={filteredCases}
        page={page}
        rowsPerPage={rowsPerPage}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        filteredCases={filteredCases}
      />

      {/* Pagination Controls */}
      <PaginationControls
        totalCases={filteredCases.length}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={handleSetPage}
      />

      {/* Snackbar for Refresh Feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CasesListPage;
