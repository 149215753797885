import React from "react";
import { Box, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { useTheme } from "@mui/material/styles";

interface CaseInfoSectionProps {
  device: string;
  from: string;
}

const CaseInfoSection: React.FC<CaseInfoSectionProps> = ({ device, from }) => {
  const theme = useTheme();

  return (
    <>
      {/* Device */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
          marginTop: "0.5rem",
          marginRight: "1.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <EmailIcon
            sx={{ width: 20, height: 20, color: theme.palette.grey[500] }}
          />
        </Box>
        <Box>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            Device
          </Typography>
          <Typography variant="body2">{device}</Typography>
        </Box>
      </Box>

      {/* From Address */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
          marginTop: "0.5rem",
          marginRight: "1.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <PermIdentityIcon
            sx={{ width: 20, height: 20, color: theme.palette.grey[500] }}
          />
        </Box>
        <Box>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            From
          </Typography>
          <Typography variant="body2">{from}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default CaseInfoSection;
