import React from "react";
import { Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface DeviceChipProps {
  device: string;
}

const DeviceChip: React.FC<DeviceChipProps> = ({ device }) => {
  const theme = useTheme();

  // Determine if the current theme is dark
  const isDarkMode = theme.palette.mode === "dark";

  // Define background and border colors based on theme mode
  const backgroundColor = isDarkMode
    ? theme.palette.grey[800] // Dark mode background
    : theme.palette.grey[100]; // Light mode background

  const borderColor = isDarkMode
    ? theme.palette.grey[700] // Dark mode border
    : theme.palette.grey[300]; // Light mode border

  // Define text color based on theme mode
  const textColor = isDarkMode
    ? theme.palette.common.white // Dark mode text
    : theme.palette.text.primary; // Light mode text

  return (
    <Chip
      label={device}
      size="small"
      sx={{
        backgroundColor: backgroundColor,
        color: textColor,
        borderRadius: "8px",
        padding: "0 8px",
        fontSize: "0.75rem",
        border: `1px solid ${borderColor}`,
        height: "24px",
        // Optional: Add transition for smooth color changes
        transition: "background-color 0.3s, color 0.3s, border-color 0.3s",
        // Optional: Adjust hover state
        "&:hover": {
          backgroundColor: isDarkMode
            ? theme.palette.grey[700]
            : theme.palette.grey[200],
          borderColor: isDarkMode
            ? theme.palette.grey[600]
            : theme.palette.grey[400],
        },
      }}
    />
  );
};

export default DeviceChip;
