import React from "react";
import { Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface DescriptionSectionProps {
  description: string;
}

const DescriptionSection: React.FC<DescriptionSectionProps> = ({
  description,
}) => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        flex: 1,
        padding: theme.spacing(2),
        borderRadius: "8px",
        PaperShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", marginBottom: theme.spacing(1) }}
      >
        Description
      </Typography>
      <Typography variant="body2" color={theme.palette.text.primary}>
        {description}
      </Typography>
    </Paper>
  );
};

export default DescriptionSection;
